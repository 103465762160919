import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Redirect } from "react-router-dom";
import ConnectWrapper from "../component/connect/ConnectWrapper";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      redirect: false,
    };
    this.chatRef = React.createRef(); // Create a ref
    this.handleShowChat = this.handleShowChat.bind(this);
  }
  componentDidMount() {
    const isMobile =
      /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop|Mobile/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      this.setState({ isMobile: true });
    }
  }
  handleShowChat() {
    if (this.state.isMobile) {
      this.setState({ redirect: true });
    }
    if (this.chatRef.current) {
      this.chatRef.current.openChat(); // Call the child method via the ref
    }
  }
  render() {
    const { isMobile, redirect } = this.state;
    // If redirect is true, navigate to the new page
    if (redirect) {
      return <Redirect to="/connect" />;
    }
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Blog" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Blog List"} />
        {/* End Breadcrump Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area ptb--80 bg_color--1">
          <div className="container">
            <p>
              Explore our insightful blog to stay informed about mortgages,
              refinancing, and home-buying tips. Discover expert advice, tools,
              and resources to guide you through every step of your financial
              journey.
            </p>
            <BlogList limit="20" />
            <div className="row mt--20">
              <div className="col-lg-12">
                {/* Start Pagination Area */}
                <Pagination />
                {/* End Pagination Area */}
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start connect button */}
        <ConnectWrapper ref={this.chatRef} isMobile={isMobile} />
        {/* End connect button */}
        {/* Start Footer Style  */}
        <Footer onChildAction={this.handleShowChat} isMobile={isMobile} />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default Blog;
