import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import CalculatorMortgage from "../../component/calculators/CalculatorMortgage";
import { Redirect } from "react-router-dom";
import ConnectWrapper from "../../component/connect/ConnectWrapper";
import AirbnbVsLongTermCalculator from "../../component/calculators/AirbnbVsLongTermCalculator";
class RentalProfitability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      redirect: false,
    };
    this.chatRef = React.createRef(); // Create a ref
    this.handleShowChat = this.handleShowChat.bind(this);
  }
  componentDidMount() {
    const isMobile =
      /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop|Mobile/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      this.setState({ isMobile: true });
    }
  }
  handleShowChat() {
    if (this.state.isMobile) {
      this.setState({ redirect: true });
    }
    if (this.chatRef.current) {
      this.chatRef.current.openChat(); // Call the child method via the ref
    }
  }
  render() {
    const { isMobile, redirect } = this.state;
    // If redirect is true, navigate to the new page
    if (redirect) {
      return <Redirect to="/connect" />;
    }
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Mortgage Calculator" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Airbnb vs. Long-Term Rental Profitability Calculator
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <AirbnbVsLongTermCalculator />
        </div>
        {/* End Contact Page Area  */}

        {/* Start connect button */}
        <ConnectWrapper ref={this.chatRef} isMobile={isMobile} />
        {/* End connect button */}
        {/* Start Footer Style  */}
        <Footer onChildAction={this.handleShowChat} isMobile={isMobile} />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default RentalProfitability;
