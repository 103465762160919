import React, { Component } from "react";
import { Box, Typography, Link, Card, CardContent, Grid } from "@mui/material";

const latestNews = [
  {
    title: "Mortgage Rates Rise Again: What Does it Mean for Homebuyers?",
    url: "https://www.bankrate.com/mortgages/mortgage-rates/",
    description:
      "Mortgage rates have been on the rise in recent weeks. Learn how this affects your home buying or refinancing plans.",
    date: "2023-11-20",
    source: "Bankrate",
  },
  {
    title: "Housing Market Cools Down: Is Now a Good Time to Buy?",
    url: "https://www.realtor.com/news/trends/2023-11-17/housing-market-cools-down-is-now-a-good-time-to-buy",
    description:
      "The housing market is showing signs of cooling down. Experts weigh in on whether this is a good time for buyers to enter the market.",
    date: "2023-11-17",
    source: "Realtor.com",
  },
  {
    title: "Refinancing Rates Rise: Should You Still Consider It?",
    url: "https://www.nerdwallet.com/article/mortgages/refinance-rates",
    description:
      "Refinancing rates have been on the rise, but there may still be opportunities to save money by refinancing your mortgage.",
    date: "2023-11-15",
    source: "NerdWallet",
  },
  {
    title: "Fed Raises Interest Rates Again: What It Means for the Economy",
    url: "https://www.cnbc.com/2023/11/01/fed-raises-interest-rates-again-what-it-means-for-the-economy.html",
    description:
      "The Federal Reserve has once again raised interest rates. Learn how this could impact the economy and your finances.",
    date: "2023-11-01",
    source: "CNBC",
  },
  {
    title: "Housing Market Forecast: What to Expect in 2024",
    url: "https://www.forbes.com/advisor/mortgages/housing-market-forecast-2024/",
    description:
      "Experts predict a more balanced housing market in 2024. Learn what to expect for home prices, mortgage rates, and more.",
    date: "2023-11-16",
    source: "Forbes Advisor",
  },
];
class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const lsContent = latestNews.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {lsContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href="/service-details">
                <div className="service service__style--2">
                  <div className="content">
                    <h3 className="title">
                      <a
                        href={val.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {val.title}
                      </a>
                    </h3>
                    <p>{val.description}</p>
                    <p className="small">Source: {val.source}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
