import React, { Component } from "react";
import { auth, db } from "../config/firebase";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./CustomChat.css";
import {
  MainContainer,
  ChatContainer,
  ConversationHeader,
  Avatar,
  MessageList,
  Message,
  MessageInput,
  Button,
} from "@chatscope/chat-ui-kit-react";
import {
  getDocs,
  doc,
  collection,
  onSnapshot,
  setDoc,
  addDoc,
  query,
  where,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { addClientMessage, getAgentDetails } from "../config/FBHelper";
import avatarImg from "../../assets/images/loanconsultants/loanofficer-1.jpg";

class Messenger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [], // Store chat messages
      currentUser: null, // Store the current logged-in user
      currentLeadId: "",
      currentChatId: "",
      agentId: "",
      agentName: "",
      agentAvatarUrl: "",
    };
    this.messagesRef = collection(db, "messages");
    this.listenForMessages = this.listenForMessages.bind(this);
    this.getAgentDetails = this.getAgentDetails.bind(this);
  }

  componentDidMount() {
    this.setState({ currentUser: auth.currentUser.uid });
    this.setState({ currentChatId: this.props.chatID });
    this.setState({ currentLeadId: this.props.leadID });
    //console.log("props chat id: " + this.props.chatID);
    this.getAgentDetails(this.props.agentID);
    this.listenForMessages(this.props.chatID);
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.authUnsubscribe) this.authUnsubscribe();
  }

  // Set up a listener for messages matching the current chat ID
  listenForMessages(currentChatId) {
    const collPath = "messages";
    if (currentChatId) {
      const q = query(
        collection(db, collPath),
        where("chatId", "==", currentChatId),
        orderBy("timestamp")
      );
      this.unsubscribe = onSnapshot(q, (snapshot) => {
        const newMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.setState({ messages: newMessages });
      });
    }
  }

  // Send a new message with the current user as the sender
  handleSend = (text) => {
    const { currentUser, currentChatId } = this.state;

    if (currentUser) {
      const newMessage = {
        message: text,
        sender: currentUser, // Use current user's UID as sender
        chatId: currentChatId,
      };
      if (newMessage != "") addClientMessage(newMessage);
      // this.messagesRef.add(newMessage).catch((error) => {
      //   console.error("Error adding message: ", error);
      // });
    } else {
      console.error("No user is logged in.");
    }
  };
  handleCloseChat = () => {
    const { closeChat } = this.props;

    // Sign out the current user
    auth
      .signOut()
      .then(() => {
        console.log("User signed out.");
        closeChat(); // Close the chat
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };
  async getAgentDetails(agentId) {
    const agentRec = await getAgentDetails(agentId);
    if (agentRec) {
      console.log(agentRec);
      this.setState({
        agentName: agentRec.firstName + " " + agentRec.lastName,
      });
      this.setState({ agentAvatarUrl: agentRec.avatarUrl });
    }
  }
  render() {
    const { messages, currentUser, agentName, agentAvatarUrl } = this.state;
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <MainContainer>
          <ChatContainer>
            <ConversationHeader>
              <Avatar name={agentName} src={agentAvatarUrl} />
              <ConversationHeader.Content userName={agentName} />
              <ConversationHeader.Actions>
                <Button
                  icon="Close"
                  onClick={this.handleCloseChat}
                  title="Close chat"
                  aria-label="Close chat"
                />
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList>
              {messages.map((msg) => (
                <Message
                  key={msg.id}
                  model={{
                    message: msg.message,
                    sentTime: msg.timestamp
                      ? msg.timestamp.toDate().toLocaleTimeString()
                      : "Just now",
                    sender: msg.sender,
                    direction:
                      msg.sender === currentUser ? "outgoing" : "incoming",
                    position: "single",
                  }}
                >
                  <Message.Header
                    sender="Joe"
                    sentTime={
                      msg.timestamp
                        ? msg.timestamp.toDate().toLocaleTimeString()
                        : "Just now"
                    }
                  />
                </Message>
              ))}
            </MessageList>
            <MessageInput
              attachButton={false}
              placeholder="Type message here"
              onSend={this.handleSend}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    );
  }
}
export default Messenger;
