import React, { Component } from "react";
import { auth } from "../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { registerLead } from "../config/FBHelper";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

class ConnectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnPhone: "",
      rnZip: "",
      otp: "",
      showInfoScreen: true,
      showOTPScreen: false,
      verificationCode: "",
      confirmationResult: null,
      error: null,
      leadInfo: {},
      recaptcha: null,
      emailError: "",
      phoneError: "",
      zipError: "",
    };
    this.onSignInSubmit = this.onSignInSubmit.bind(this);
    this.onVerifyCodeSubmit = this.onVerifyCodeSubmit.bind(this);
    this.handleOTPChange = this.handleOTPChange.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleZipChange = this.handleZipChange.bind(this);
    auth.settings.appVerificationDisabledForTesting = false;
  }

  componentDidMount() {
    // Initialize reCAPTCHA when the component mounts
    window.recaptchaVerifier = new RecaptchaVerifier(auth, this.recaptcha, {
      size: "invisible", // 'invisible' or 'normal' for visible widget
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("Recaptcha verified");
        this.onSignInSubmit();
      },
      "expired-callback": () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        console.log("Recaptcha expired");
      },
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }

  componentWillUnmount() {
    console.log("unmounting connectform");
    // Clean up reCAPTCHA verifier if necessary
    window.recaptchaVerifier = null;
  }

  // Validate OTP
  onVerifyCodeSubmit(e) {
    e.preventDefault();
    const { otp, confirmationResult, leadInfo } = this.state;

    if (confirmationResult) {
      confirmationResult
        .confirm(otp)
        .then(async (result) => {
          // User signed in successfully.
          const user = result.user;
          var uInfo = { ...leadInfo, UID: user.uid };
          var objInfo = await registerLead(uInfo);
          console.log("Lead Info: " + objInfo);
          this.props.onLeadSuccess(objInfo.leadId, objInfo.chatId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onSignInSubmit = (e) => {
    if (e) e.preventDefault();
    try {
      const phoneNumber = "+1" + this.state.rnPhone;
      console.log(phoneNumber);
      // Reset reCAPTCHA if already initialized
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.render().then((widgetId) => {
          window.grecaptcha.reset(widgetId); // Reset reCAPTCHA to ensure freshness
        });
      }
      const appVerifier = window.recaptchaVerifier;
      const userInfo = {
        fullName: this.state.rnName,
        email: this.state.rnEmail,
        phone: phoneNumber,
        zipCode: this.state.rnZip,
        UID: "",
        status: "pending",
        created: new Date(),
      };
      this.setState({ leadInfo: userInfo });

      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((results) => {
          this.setState({ showOTPScreen: true });
          this.setState({ confirmationResult: results });
          console.log("SMS Sent");
        })
        .catch((error) => {
          // Error; SMS not sent
          console.error("Error during signInWithPhoneNumber", error);
          // Reset reCAPTCHA in case of error
          if (this.recaptchaVerifier) {
            this.recaptchaVerifier.render().then((widgetId) => {
              grecaptcha.reset(widgetId);
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  handleOTPChange(val) {
    console.log(val);
    this.setState({ otp: val });
  }
  handleRetry() {
    // Reset state to show the phone input form again
    this.setState({ confirmationResult: null });
    this.setState({ verificationCode: "" });
    this.setState({ showOTPScreen: false });
    // Reset reCAPTCHA
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.render().then((widgetId) => {
        window.grecaptcha.reset(widgetId);
      });
    }
  }
  handleEmailChange(e) {
    const value = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(value);
    if (emailRegex.test(value)) {
      this.setState({ emailError: "" });
    } else {
      this.setState({ emailError: "Invalid entry" });
    }
  }
  handlePhoneChange(e) {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value) && value.length === 10) {
      this.setState({ phoneError: "" });
    } else {
      this.setState({ phoneError: "Invalid entry" });
    }
  }
  handleZipChange(e) {
    const value = e.target.value;
    if (/^\d{0,5}$/.test(value)) {
      this.setState({ zipError: "" });
    } else {
      this.setState({ zipError: "Invalid entry" });
    }
  }
  render() {
    return (
      <>
        <div>
          <div ref={(ref) => (this.recaptcha = ref)}></div>
          <div className="container">
            <div className="row align-items-start">
              <div className="col-lg-12">
                <div className="section-title text-left mb--15 mt-3">
                  <h3 className="title">LoanDeer Connect</h3>
                </div>
              </div>
            </div>
            <div className="row align-items-start">
              <div className="col-lg-12">
                {!this.state.showOTPScreen ? (
                  <div>
                    <p className="description">
                      Please provide the necessary information to connect with
                      loan consultants in your area.
                    </p>
                    <Box
                      component="form"
                      sx={{ "& .MuiTextField-root": { m: 1, width: "90%" } }}
                      noValidate
                      autoComplete="off"
                      id="form1"
                      onSubmit={this.onSignInSubmit}
                    >
                      <div>
                        <TextField
                          required
                          id="txtName"
                          label="Full Name"
                          size="small"
                          onInput={(e) => {
                            this.setState({ rnName: e.target.value });
                          }}
                        />
                        <TextField
                          required
                          id="txtEmail"
                          label="Email"
                          type="email"
                          value={this.state.rnEmail}
                          size="small"
                          error={this.state.emailError !== ""}
                          helperText={this.state.emailError}
                          onBlur={this.handleEmailChange}
                          onInput={(e) => {
                            this.setState({ rnEmail: e.target.value });
                          }}
                        />
                        <TextField
                          required
                          id="txtPhone"
                          label="Mobile Phone #"
                          type="tel"
                          placeholder="1234567890"
                          value={this.state.rnPhone}
                          size="small"
                          error={this.state.phoneError !== ""}
                          helperText={this.state.phoneError}
                          onBlur={this.handlePhoneChange}
                          onInput={(e) => {
                            this.setState({ rnPhone: e.target.value });
                          }}
                        />
                        <TextField
                          required
                          id="txtZipcode"
                          label="Zip Code"
                          defaultValue=""
                          value={this.state.rnZip}
                          size="small"
                          error={this.state.zipError !== ""}
                          helperText={this.state.zipError}
                          onBlur={this.handleZipChange}
                          onInput={(e) => {
                            this.setState({ rnZip: e.target.value });
                          }}
                        />

                        <h6 className="p-0 pt-1 pb-2 small">
                          This site is protected by reCAPTCHA. By clicking
                          Submit button, I agree to LoanDeer{" "}
                          <a href="/termsconditions" target="_blank">
                            terms & conditions
                          </a>
                        </h6>
                        <h6 className="p-0 pb-3 small">
                          A verification code will be sent to your phone. SMS
                          charges may apply.
                        </h6>
                        <div className="pt-4 text-center">
                          <button
                            className="rn-button-style--2 btn-solid"
                            type="submit"
                            value="submit"
                            name="btnSubmit"
                            id="btnSubmit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Box>
                  </div>
                ) : (
                  <div>
                    <p className="description pt-5 pb-3">
                      Please enter six digit verification code.
                    </p>
                    <form id="form2" onSubmit={this.onVerifyCodeSubmit}>
                      <MuiOtpInput
                        length={6}
                        value={this.state.otp}
                        autoFocus
                        TextFieldsProps={(index) => ({
                          size: "small",
                          placeholder: String("-"),
                        })}
                        onChange={this.handleOTPChange}
                      />
                      <div className="pt-4 text-center">
                        <button
                          className="rn-button-style--2 btn-solid"
                          type="submit"
                          value="verify"
                          name="btnVerifyOtp"
                          id="btnVerifyOtp"
                        >
                          Verify
                        </button>
                      </div>
                      <div className="p-0 pt-4 text-center">
                        <h6 className="small">
                          Didn't get the code?{" "}
                          <a href="#" onClick={this.handleRetry}>
                            Try again
                          </a>
                        </h6>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConnectForm;
