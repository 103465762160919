import React, { Component } from "react";
import {
  FiHome,
  FiRepeat,
  FiDollarSign,
  FiCheck,
  FiPocket,
} from "react-icons/fi";
import { FaAirbnb } from "react-icons/fa6";
const ServiceList = [
  {
    icon: <FiHome />,
    title: "Mortgage Calculator",
    url: "/mortgage-calculator",
    description:
      "Estimate your monthly payments and total loan costs to plan your home purchase.",
  },
  {
    icon: <FiRepeat />,
    title: "Refinance Calculator",
    url: "/refinance-calculator",
    description:
      "See how refinancing can lower your payments and save you money over time.",
  },
  {
    icon: <FiDollarSign />,
    title: "How much can I afford?",
    url: "/prequalify-calculator",
    description:
      "Find out how much home you can afford based on your income and expenses.",
  },
  {
    icon: <FiCheck />,
    title: "First-Time Homebuyer Checklist Tool",
    url: "/homebuyer-checklist",
    description:
      "An interactive tool that provides step-by-step guidance for first-time buyers.",
  },
  {
    icon: <FiPocket />,
    title: "Budget Planning Tool for Homebuyers",
    url: "/budget-planning",
    description: "Helps you create a budget for purchasing a home.",
  },
  {
    icon: <FaAirbnb />,
    title: "Airbnb vs Long-Term Rental Profitability Calculator",
    url: "/rental-profitability-calculator",
    description:
      "Helps you decide between short-term rentals (like Airbnb) or long-term leases.",
  },
];

class NewsList extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href={val.url}>
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default NewsList;
