import React, { Component, Fragment } from "react";
import { db, auth } from "../config/firebase";
import ConnectForm from "./ConnectForm";
import Messenger from "./Messenger";
import LeadWait from "./LeadWait";

class Connect extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      leadId: "",
      chatId: null,
      agentId: "",
      leadAccepted: false,
    };
    this.authUnsubscribe = null;
    this.resetPage = this.resetPage.bind(this);
  }

  componentDidMount() {
    // Set up the listener
    this.authUnsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        // User is signed in
        this.setState({ user: currentUser });
      } else {
        // User is signed out
        this.setState({ user: null });
      }
    });
  }

  componentWillUnmount() {
    // Clean up the listener
    if (this.authUnsubscribe) {
      this.authUnsubscribe();
    }
  }
  handleCurrentLeadId = (leadId, chatId) => {
    console.log("Received lead id from connect form: " + leadId);
    console.log("Received chat id from connect form: " + chatId);
    this.setState({ leadId });
    this.setState({ chatId });
  };
  updateLeadStatus = (status, agentId) => {
    this.setState({ leadAccepted: status });
    this.setState({ agentId: agentId });
  };
  resetPage() {
    auth
      .signOut()
      .then(() => {
        console.log("User signed out.");
        this.setState({ leadId: "" });
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  }

  render() {
    const { user, leadId, chatId, leadAccepted, agentId } = this.state;
    const { closeChat } = this.props;
    console.log("passing lead id: " + leadId);
    console.log("load chat id: " + chatId);
    return (
      <div style={{ height: "100%" }}>
        {user && leadId !== "" ? (
          !leadAccepted ? (
            <LeadWait
              leadID={leadId}
              onStatusUpdate={this.updateLeadStatus}
              closeChat={closeChat}
            />
          ) : (
            <Messenger
              leadID={leadId}
              chatID={chatId}
              closeChat={closeChat}
              agentID={agentId}
            />
          )
        ) : (
          <ConnectForm onLeadSuccess={this.handleCurrentLeadId} />
        )}
      </div>
    );
  }
}

export default Connect;
