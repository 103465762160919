import React, { Component } from "react";
import { Avatar } from "@mui/material";

import brand1 from "../assets/images/loanconsultants/loanofficer-1.jpg";
import brand2 from "../assets/images/loanconsultants/loanofficer-2.jpg";
import brand3 from "../assets/images/loanconsultants/loanofficer-3.jpg";
import brand4 from "../assets/images/loanconsultants/loanofficer-4.jpg";
import brand5 from "../assets/images/loanconsultants/loanofficer-5.jpg";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <Avatar
              alt="Remy Sharp"
              src={brand1}
              sx={{ width: 106, height: 106 }}
            />
            <span className="pt-3 m-0">James Frank</span>
            <p className="small p-0 m-0 lh-1">NMLS# 232323</p>
          </li>
          <li>
            <Avatar
              alt="Loan Consultant"
              src={brand2}
              sx={{ width: 106, height: 106 }}
            />
            <span className="pt-3 m-0">Andrea Smith</span>
            <p className="small p-0 m-0 lh-1">NMLS# 232323</p>
          </li>
          <li>
            <Avatar
              alt="Loan Consultant"
              src={brand3}
              sx={{ width: 106, height: 106 }}
            />
            <span className="pt-3 m-0">John Berg</span>
            <p className="small p-0 m-0 lh-1">NMLS# 232323</p>
          </li>
          <li>
            <Avatar
              alt="Loan Consultant"
              src={brand4}
              sx={{ width: 106, height: 106 }}
            />
            <span className="pt-3 m-0">Mike Snyder</span>
            <p className="small p-0 m-0 lh-1">NMLS# 232323</p>
          </li>
          <li>
            <Avatar
              alt="Loan Consultant"
              src={brand5}
              sx={{ width: 106, height: 106 }}
            />
            <span className="pt-3 m-0">Mary Frank</span>
            <p className="small p-0 m-0 lh-1">NMLS# 232323</p>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
