import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/about-3.jpg";
import { Redirect } from "react-router-dom";
import ConnectWrapper from "../component/connect/ConnectWrapper";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      redirect: false,
    };
    this.chatRef = React.createRef(); // Create a ref
    this.handleShowChat = this.handleShowChat.bind(this);
  }
  componentDidMount() {
    const isMobile =
      /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop|Mobile/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      this.setState({ isMobile: true });
    }
  }
  handleShowChat() {
    if (this.state.isMobile) {
      this.setState({ redirect: true });
    }
    if (this.chatRef.current) {
      this.chatRef.current.openChat(); // Call the child method via the ref
    }
  }
  render() {
    const { isMobile, redirect } = this.state;
    // If redirect is true, navigate to the new page
    if (redirect) {
      return <Redirect to="/connect" />;
    }
    let title = "About Us",
      description =
        "At LoanDeer LLC, we believe in making homeownership dreams a reality by connecting you with live, certified loan consultants in your area. Our platform eliminates the guesswork by providing personalized mortgage advice from experienced professionals—not chatbots. We’re here to guide you every step of the way, ensuring your home financing journey is smooth and stress-free.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={about} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <h4 className="subtitle">
                        Welcome to LoanDeer LLC – Your Trusted Mortgage Partner.
                      </h4>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">What Sets Us Apart?</h3>
                          <p>
                            <ul>
                              <li>
                                <strong>Real Experts, Real Answers:</strong> We
                                don’t rely on generic automated systems.
                                Instead, we connect you with real people who
                                understand the complexities of the mortgage
                                process. Our certified consultants provide
                                tailored advice based on your unique financial
                                situation, ensuring you make informed decisions.
                              </li>
                              <li>
                                <strong>Personalized, Local Expertise:</strong>{" "}
                                By using your zip code, we match you with a
                                local loan consultant who knows your market.
                                This ensures that you get advice relevant to
                                your area’s housing trends, loan programs, and
                                property tax considerations.
                              </li>
                              <li>
                                <strong>Free and Hassle-Free Service:</strong>{" "}
                                Our services are entirely free of charge. No
                                hidden fees, no commitments—just expert guidance
                                to help you achieve your goals.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Mission</h3>
                  <p>
                    Our mission is simple:{" "}
                    <strong>
                      To make expert mortgage advice accessible to everyone.
                    </strong>{" "}
                    Whether you’re buying your first home, refinancing, or
                    exploring your options, we’re committed to empowering you
                    with the knowledge and resources you need to succeed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End CounterUp Area */}
        <div className="rn-counterup-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  <h3 className="fontWeight500">How It Works</h3>
                  <ol>
                    <li>
                      <strong>Provide Basic Information:</strong> Share your
                      name, email, phone number, and zip code. This allows us to
                      connect you with the right loan consultant in your area.
                    </li>
                    <li>
                      <strong>Connect with a Consultant:</strong> Within
                      minutes, you’ll be chatting with a certified loan
                      professional who will answer your questions and offer
                      personalized solutions.
                    </li>
                    <li>
                      <strong>Make Informed Decisions:</strong> With expert
                      guidance, you’ll have the confidence to take the next
                      steps—whether it’s securing a mortgage, refinancing, or
                      planning for the future.
                    </li>
                  </ol>
                </p>

                <p>
                  <h3 className="fontWeight500">Our Services</h3>
                  <p>
                    We offer a range of services to meet your home financing
                    needs:
                  </p>
                  <ul>
                    <li>
                      <strong>First-Time Buyer Assistance:</strong> Tailored
                      advice to help you navigate the home-buying process.
                    </li>
                    <li>
                      <strong>Mortgage Refinancing:</strong> Explore options to
                      lower your monthly payments or shorten your loan term.
                    </li>
                    <li>
                      <strong>Pre-Qualification Guidance:</strong> Understand
                      your buying power with personalized pre-qualification
                      estimates.
                    </li>
                    <li>
                      <strong>Affordability Tools:</strong> Use our calculators
                      to estimate monthly payments, refinancing savings, and
                      more.
                    </li>
                  </ul>
                </p>
                <p>
                  <h3 className="fontWeight500">Why Choose LoanDeer LLC?</h3>
                  <ul>
                    <li>
                      <strong>Unparalleled Expertise:</strong> Our network of
                      consultants brings years of experience in the mortgage
                      industry.
                    </li>
                    <li>
                      <strong>Local Market Knowledge:</strong> Get advice from
                      professionals who understand your area’s unique housing
                      landscape.
                    </li>
                    <li>
                      <strong>Customer-Centric Approach:</strong> Your
                      satisfaction is our top priority. We’re here to listen,
                      guide, and support you throughout your journey.
                    </li>
                  </ul>
                </p>

                <p>
                  <h3 className="fontWeight500">What Our Clients Say</h3>
                  <blockquote>
                    "LoanDeer LLC connected me with an amazing consultant who
                    made refinancing simple and stress-free. Highly recommend!"
                    <footer>– Sarah T., Michigan</footer>
                  </blockquote>
                  <blockquote>
                    "I was unsure about how much home I could afford, but their
                    pre-qualification tools and expert advice gave me the
                    clarity I needed."
                    <footer>– James P., Michigan</footer>
                  </blockquote>
                  <blockquote>
                    "The live consultant answered all my questions in real-time.
                    I felt confident making my decision."
                    <footer>– Emily R., Florida</footer>
                  </blockquote>
                </p>

                <p>
                  <h3 className="fontWeight500">
                    Join the Thousands We’ve Helped
                  </h3>
                  <p>
                    Since our founding, LoanDeer LLC has helped countless
                    individuals and families secure the financing they need to
                    achieve their homeownership dreams. We take pride in being a
                    trusted partner in one of life’s most significant decisions.
                  </p>
                </p>

                <p>
                  <h3 className="fontWeight500">Let’s Get Started</h3>
                  <p>
                    Ready to take the next step? Click the{" "}
                    <strong>"Connect Now"</strong> button to chat with a
                    certified loan consultant today. It’s free, fast, and
                    designed with your needs in mind.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Start connect button */}
        <ConnectWrapper ref={this.chatRef} isMobile={isMobile} />
        {/* End connect button */}
        {/* Start Footer Style  */}
        <Footer onChildAction={this.handleShowChat} isMobile={isMobile} />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default About;
