import React, { useState } from "react";
import { contactUsEntry } from "../../component/config/FBHelper";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    const objItem = {
      name: name,
      email: email,
      phone: phone,
      subject: subject,
      message: message,
    };

    contactUsEntry(objItem);
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 10000);

  return !result ? (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input
          type="text"
          name="fullname"
          placeholder="Your Name"
          required
          onInput={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <div className="rn-form-group">
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          required
          onInput={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className="rn-form-group">
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          required
          onInput={(e) => {
            setPhone(e.target.value);
          }}
        />
      </div>

      <div className="rn-form-group">
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          required
          onInput={(e) => {
            setSubject(e.target.value);
          }}
        />
      </div>

      <div className="rn-form-group">
        <textarea
          name="message"
          placeholder="Your Message"
          required
          onInput={(e) => {
            setMessage(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>
    </form>
  ) : (
    <div className="rn-form-group">{result ? <Result /> : null}</div>
  );
}
export default ContactForm;
