import React, { Component, Fragment } from "react";
import { db } from "../../component/config/firebase";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { Link } from "react-router-dom/cjs/react-router-dom";

class BLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PostList: [], // Store blog posts
      imageBaseURL: "../../assets/images/blog/",
      limit: this.props.limit,
    };
    this.fetchBlogs = this.fetchBlogs.bind(this);
  }
  componentDidMount() {
    this.fetchBlogs();
  }

  async fetchBlogs() {
    const q = query(
      collection(db, "blogPosts"),
      orderBy("date"),
      limit(this.state.limit)
    );

    try {
      const blogSnapshot = await getDocs(q);

      const blogs = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      this.setState({ PostList: blogs });
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  }
  render() {
    const { PostList, imageBaseURL } = this.state;
    return (
      <Fragment>
        <div className="row">
          {PostList.map((blog) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={blog.id}>
              <div className="blog blog-style--1">
                <div className="thumbnail">
                  <a href="/blog-details">
                    <img
                      className="w-100"
                      src={require(`../../assets/images/blog/${blog.image}`)}
                      alt={blog.title}
                    />
                  </a>
                </div>
                <div className="content">
                  <p className="blogtype">{blog.category}</p>
                  <h4 className="title">
                    <Link
                      className="text-white"
                      to={{
                        pathname: "/blog-details",
                        state: blog, // your data array of objects
                      }}
                    >
                      {blog.title
                        .slice(0, 68)
                        .slice(0, blog.title.slice(0, 68).lastIndexOf(" "))}
                      ...
                    </Link>
                  </h4>
                  <div className="blog-btn">
                    <Link
                      className="rn-btn text-white"
                      to={{
                        pathname: "/blog-details",
                        state: blog, // your data array of objects
                      }}
                    >
                      Read More
                    </Link>
                    {/* <button
                      className="rn-btn text-white"
                      onClick={() => this.goToDetails(blog)}
                    >
                      Read More
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default BLogList;
