export const StatesList = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export const CreditScoresList = ["680-700", "700-720", "720-740", "740-800"];

export const CommonTermsList = [
  {
    term: "Adjustable Rate Mortgage (ARM)",
    details:
      "A type of mortgage where the interest rate can change periodically based on an index, leading to fluctuations in monthly payments.",
  },
  {
    term: "Amortization",
    details:
      "The process of paying off a loan over time through scheduled payments, including both principal and interest.",
  },
  {
    term: "Annual Percentage Rate (APR)",
    details:
      "The yearly cost of borrowing expressed as a percentage, including the interest rate and other fees.",
  },
  {
    term: "Appraisal Fee",
    details:
      "A fee charged to assess the market value of a property, typically required by lenders during the mortgage process.",
  },
  {
    term: "Credit Score",
    details:
      "A numerical representation of a borrower's creditworthiness, based on their credit history.",
  },
  {
    term: "Debt Ratio",
    details:
      "The ratio of a borrower's total monthly debts to their monthly income, used by lenders to assess risk.",
  },
  {
    term: "Down Payment",
    details:
      "An upfront payment made towards the purchase price of a home, usually expressed as a percentage of the total cost.",
  },
  {
    term: "Equity",
    details:
      "The difference between the current market value of a property and the amount still owed on the mortgage.",
  },
  {
    term: "Escrow",
    details:
      "A financial arrangement where a third party holds funds for taxes, insurance, and other property-related expenses.",
  },
  {
    term: "FHA Loan",
    details:
      "A loan insured by the Federal Housing Administration, typically designed for low-to-moderate-income borrowers.",
  },
  {
    term: "Fannie Mae",
    details:
      "A government-sponsored enterprise that provides liquidity to the mortgage market by buying loans from lenders.",
  },
  {
    term: "First-time Home Buyers (FTHB) Loan Programs",
    details:
      "Special loan programs offering benefits like lower down payments and reduced interest rates for first-time homebuyers.",
  },
  {
    term: "Fixed-Rate Mortgage",
    details:
      "A mortgage where the interest rate remains constant throughout the loan term, providing predictable monthly payments.",
  },
  {
    term: "Foreclosure",
    details:
      "A legal process where a lender takes possession of a property when the borrower fails to make mortgage payments.",
  },
  {
    term: "Freddie Mac",
    details:
      "A government-sponsored enterprise that buys mortgages from lenders, providing liquidity in the housing market.",
  },
  {
    term: "Home Appraisal",
    details:
      "An assessment of a property's market value by a licensed appraiser, often required during the home-buying process.",
  },
  {
    term: "Index",
    details:
      "A benchmark interest rate used to calculate the interest rate for adjustable-rate mortgages.",
  },
  {
    term: "Interest Rate",
    details:
      "The percentage charged by a lender for borrowing money, typically expressed annually as a percentage of the loan balance.",
  },
  {
    term: "Loan-to-Value Ratio (LTV)",
    details:
      "The ratio of a loan amount to the appraised value of the property, used to determine risk.",
  },
  {
    term: "Margin",
    details:
      "The fixed percentage added to the index rate for adjustable-rate mortgages to determine the interest rate.",
  },
  {
    term: "Monthly Expenses",
    details:
      "Recurring expenses such as housing, debts, and utilities, used by lenders to assess a borrower's financial stability.",
  },
  {
    term: "Mortgage",
    details:
      "A loan used to purchase a home, with the property serving as collateral until the loan is repaid.",
  },
  {
    term: "Mortgage Insurance",
    details:
      "Insurance that protects the lender if the borrower defaults on the loan, typically required for loans with less than 20% down payment.",
  },
  {
    term: "Mortgage Refinance",
    details:
      "The process of replacing an existing mortgage with a new one, usually to obtain better terms or lower interest rates.",
  },
  {
    term: "Mortgage Term",
    details:
      "The length of time agreed upon to repay a mortgage, typically 15, 20, or 30 years.",
  },
  {
    term: "Principal",
    details:
      "The original loan amount or remaining balance on a mortgage, excluding interest.",
  },
  {
    term: "Property Taxes",
    details:
      "Taxes levied by local governments on real estate, typically based on the property's assessed value.",
  },
  {
    term: "Reverse Mortgage",
    details:
      "A loan available to homeowners 62 or older, allowing them to convert home equity into cash while still living in the home.",
  },
  {
    term: "Second Mortgage",
    details:
      "An additional loan taken against a property's equity, often used for home improvements or debt consolidation.",
  },
  {
    term: "Subprime Mortgage",
    details:
      "A type of mortgage offered to borrowers with lower credit scores, typically with higher interest rates.",
  },
];

export const RefinanceFaqList = [
  {
    question: "What is mortgage refinancing?",
    answer:
      "Mortgage refinancing involves replacing your existing home loan with a new one, typically to secure a lower interest rate, reduce monthly payments, or change the loan term.",
  },
  {
    question: "How do I know if refinancing is right for me?",
    answer:
      "Refinancing may be beneficial if you can lower your interest rate, reduce monthly payments, or shorten your loan term. It’s also worth considering if you want to switch from an adjustable-rate to a fixed-rate mortgage.",
  },
  {
    question: "What are closing costs for refinancing?",
    answer:
      "Closing costs for refinancing typically range from 2% to 5% of the loan amount. They include fees for appraisal, title search, and application, among others.",
  },
  {
    question: "What is a break-even point in refinancing?",
    answer:
      "The break-even point is the time it takes to recover the closing costs of refinancing through monthly savings. If you plan to stay in your home beyond this point, refinancing could save you money.",
  },
  {
    question: "Can I refinance with bad credit?",
    answer:
      "Refinancing with bad credit is possible but may come with higher interest rates. Improving your credit score before refinancing can help you qualify for better terms.",
  },
  {
    question: "What is cash-out refinancing?",
    answer:
      "Cash-out refinancing allows you to replace your existing mortgage with a new one for a higher amount, receiving the difference in cash. It's often used for home improvements or paying off high-interest debts.",
  },
  {
    question: "How does refinancing affect my credit score?",
    answer:
      "Refinancing can temporarily lower your credit score due to hard inquiries and changes in your credit utilization. However, regular payments on the new loan can improve your score over time.",
  },
  {
    question: "Can I refinance if I have an FHA loan?",
    answer:
      "Yes, you can refinance an FHA loan. Options include FHA Streamline Refinance for reduced paperwork or switching to a conventional loan to eliminate mortgage insurance premiums.",
  },
  {
    question: "Is there a penalty for refinancing early?",
    answer:
      "Some loans have prepayment penalties for paying off the loan early, including through refinancing. Check your loan terms or consult your lender to confirm.",
  },
  {
    question: "How often can I refinance my mortgage?",
    answer:
      "There’s no legal limit to how often you can refinance, but it depends on your lender's policies and whether refinancing aligns with your financial goals.",
  },
];

export const FAQList = [
  {
    question: "How quickly can I connect with a consultant?",
    answer:
      "At LoanDeer LLC, we prioritize your time and ensure that you connect with a certified loan consultant as quickly as possible. Once you provide your Name, Email, Phone Number, and Zip Code, our system identifies the best consultant in your area and initiates the connection. In most cases, you’ll be chatting with a live consultant within minutes. This service is completely free and helps you get personalized, local advice fast.",
  },
  {
    question: "What information do I need to start?",
    answer:
      "Getting started with LoanDeer LLC is simple. We only need your Name, Email, Phone Number, and Zip Code. This ensures we can connect you with the right consultant for your area. Your personal information is used solely to provide our service and remains confidential. No spam, no hassle—just expert advice tailored to your needs.",
  },
  {
    question: "Is there any cost for using your service?",
    answer:
      "Absolutely not! LoanDeer LLC offers this service completely free of charge. We connect you with certified loan consultants who provide personalized mortgage advice with no hidden fees or obligations. Our goal is to make expert guidance accessible to everyone, whether you're a first-time homebuyer or looking to refinance.",
  },
  {
    question: "What is mortgage refinancing?",
    answer:
      "Mortgage refinancing involves replacing your existing home loan with a new one, often to secure a lower interest rate, reduce monthly payments, or adjust the loan term.",
  },
  {
    question: "How do I know if refinancing is right for me?",
    answer:
      "Refinancing may be a good idea if you can lower your interest rate, reduce monthly payments, or switch to a more favorable loan type. It’s also beneficial for consolidating debt or accessing home equity.",
  },
  {
    question: "What are closing costs for refinancing?",
    answer:
      "Closing costs typically range from 2% to 5% of the loan amount and include fees for appraisals, title searches, and other administrative tasks.",
  },
  {
    question: "What is a break-even point in refinancing?",
    answer:
      "The break-even point is the time it takes to recoup your closing costs through monthly savings. If you plan to stay in your home beyond this point, refinancing could save you money.",
  },
  {
    question: "Can I refinance with bad credit?",
    answer:
      "Yes, but you may face higher interest rates. Improving your credit score can help you qualify for better terms.",
  },
  {
    question: "What is cash-out refinancing?",
    answer:
      "Cash-out refinancing allows you to replace your existing mortgage with a new one for a higher amount, giving you the difference in cash. It’s often used for home improvements or paying off high-interest debt.",
  },
  {
    question: "How does refinancing affect my credit score?",
    answer:
      "Refinancing can cause a temporary dip in your credit score due to hard inquiries. However, consistent payments on the new loan can improve your score over time.",
  },
  {
    question: "Can I refinance if I have an FHA loan?",
    answer:
      "Yes, you can refinance an FHA loan using an FHA Streamline Refinance or switch to a conventional loan to eliminate mortgage insurance premiums.",
  },
  {
    question: "Is there a penalty for refinancing early?",
    answer:
      "Some loans may include prepayment penalties. Check your loan agreement or consult your lender to confirm.",
  },
  {
    question: "How often can I refinance my mortgage?",
    answer:
      "There’s no limit to how often you can refinance, but it depends on your financial goals and whether the savings outweigh the costs.",
  },
];
