import React, { Component } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

class BudgetPlanningTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        monthlyIncome: 5000,
        monthlyExpenses: 2000,
        debtPayments: 800,
        emergencyFund: 10000,
        savingsGoal: 50000,
        savingsTimeline: 24, // in months
        closingCosts: 10000,
        renovationCosts: 5000,
        movingExpenses: 2000,
        recurringExpenses: 300,
        optionalEmergencyFund: false,
      },
      monthlySavings: 0,
      goalStatus: "",
      dtiRatio: 0,
    };
  }

  handleInputChange = (field) => (event) => {
    const value = parseFloat(event.target.value) || 0;
    this.setState(
      (prevState) => ({
        inputs: {
          ...prevState.inputs,
          [field]: value,
        },
      }),
      this.calculateBudget
    );
  };

  handleCheckboxChange = (field) => (event) => {
    this.setState(
      (prevState) => ({
        inputs: {
          ...prevState.inputs,
          [field]: event.target.checked,
        },
      }),
      this.calculateBudget
    );
  };

  calculateBudget = () => {
    const {
      monthlyIncome,
      monthlyExpenses,
      debtPayments,
      emergencyFund,
      savingsGoal,
      savingsTimeline,
      closingCosts,
      renovationCosts,
      movingExpenses,
      recurringExpenses,
      optionalEmergencyFund,
    } = this.state.inputs;

    const totalSavingsNeeded =
      savingsGoal +
      closingCosts +
      renovationCosts +
      movingExpenses +
      (optionalEmergencyFund ? emergencyFund : 0);

    const monthlySavings =
      monthlyIncome - (monthlyExpenses + recurringExpenses + debtPayments);
    const totalSavings = monthlySavings * savingsTimeline;

    let goalStatus;
    if (totalSavings >= totalSavingsNeeded) {
      goalStatus = "You are on track to meet your budget goal!";
    } else {
      goalStatus = `You need to save more or extend your timeline to meet your goal.`;
    }

    const dtiRatio = ((debtPayments / monthlyIncome) * 100).toFixed(2);

    this.setState({ monthlySavings, goalStatus, dtiRatio });
  };

  componentDidMount() {
    this.calculateBudget();
  }

  render() {
    const { inputs, monthlySavings, goalStatus, dtiRatio } = this.state;

    return (
      <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Comprehensive Budget Planning Tool for Homebuyers
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Plan your finances to achieve your home-buying goals effectively.
        </Typography>
        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          {/* Income and Expenses */}
          <Grid item xs={12}>
            <Typography variant="h6">Income and Expenses</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Monthly Income ($)"
              type="number"
              value={inputs.monthlyIncome}
              onChange={this.handleInputChange("monthlyIncome")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Monthly Expenses ($)"
              type="number"
              value={inputs.monthlyExpenses}
              onChange={this.handleInputChange("monthlyExpenses")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Debt Payments ($)"
              type="number"
              value={inputs.debtPayments}
              onChange={this.handleInputChange("debtPayments")}
              fullWidth
              helperText="Include car loans, student loans, credit card payments, etc."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Recurring Expenses ($)"
              type="number"
              value={inputs.recurringExpenses}
              onChange={this.handleInputChange("recurringExpenses")}
              fullWidth
              helperText="Include subscriptions, childcare, gym memberships, etc."
            />
          </Grid>

          {/* Savings Goal */}
          <Grid item xs={12}>
            <Typography variant="h6">Savings Goal</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Savings Goal ($)"
              type="number"
              value={inputs.savingsGoal}
              onChange={this.handleInputChange("savingsGoal")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Savings Timeline (Months)"
              type="number"
              value={inputs.savingsTimeline}
              onChange={this.handleInputChange("savingsTimeline")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Emergency Fund ($)"
              type="number"
              value={inputs.emergencyFund}
              onChange={this.handleInputChange("emergencyFund")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inputs.optionalEmergencyFund}
                  onChange={this.handleCheckboxChange("optionalEmergencyFund")}
                />
              }
              label="Include Emergency Fund in Budget"
            />
          </Grid>

          {/* Additional Costs */}
          <Grid item xs={12}>
            <Typography variant="h6">Additional Costs</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Closing Costs ($)"
              type="number"
              value={inputs.closingCosts}
              onChange={this.handleInputChange("closingCosts")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Renovation Costs ($)"
              type="number"
              value={inputs.renovationCosts}
              onChange={this.handleInputChange("renovationCosts")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Moving Expenses ($)"
              type="number"
              value={inputs.movingExpenses}
              onChange={this.handleInputChange("movingExpenses")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Results Section */}
        <Typography variant="h6" align="center">
          Results
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Monthly Savings: ${monthlySavings.toFixed(2)}
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Debt-to-Income Ratio: {dtiRatio}%
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          {goalStatus}
        </Typography>

        {/* Action Buttons */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={this.calculateBudget}
          sx={{ mt: 3 }}
        >
          Recalculate
        </Button>
      </Box>
    );
  }
}

export default BudgetPlanningTool;
