// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Home from "./home/Index";
import Connect from "./component/connect/ConnectPage";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";
import MortgageCalculator from "./elements/calculators/MortgageCalculator";
// Blocks Layout

import Team from "./blocks/Team";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import RefinanceCalculator from "./elements/calculators/RefinanceCalculator";
import PrequalifyCalculator from "./elements/calculators/PreQualifyCalculator";
import FAQ from "./elements/FAQs";
import Terms from "./elements/Terms";
import PrivacyPolicy from "./elements/PrivacyPolicy";
import Register from "./elements/Register";
import HomebuyerChecklist from "./elements/calculators/HomebuyerChecklist";
import RentalProfitability from "./elements/calculators/RentalProfitability";
import BudgetPlanning from "./elements/calculators/BudgetPlanning";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            {/* Element Layot */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details`}
              component={ServiceDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            />

            {/* Blocks Elements  */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={Team}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/connect`}
              component={Connect}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mortgage-calculator`}
              component={MortgageCalculator}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/refinance-calculator`}
              component={RefinanceCalculator}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/prequalify-calculator`}
              component={PrequalifyCalculator}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/homebuyer-checklist`}
              component={HomebuyerChecklist}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/budget-planning`}
              component={BudgetPlanning}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/rental-profitability-calculator`}
              component={RentalProfitability}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faqs`}
              component={FAQ}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/terms`}
              component={Terms}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacypolicy`}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details/:id`}
              component={BlogDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/register`}
              component={Register}
            />
            {/*<Route
              exact
              path={`${process.env.PUBLIC_URL}/google-map`}
              component={GoogleMap}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/columns`}
              component={Columns}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/pricing-table`}
              component={PricingTable}
            /> */}

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
