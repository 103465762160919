import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import Connect from "./Connect";
import "./connect.css";

class ConnectPage extends Component {
  constructor() {
    super();
    this.state = {
      sWidth: "",
      sHeight: "",
    };
  }
  componentDidMount() {
    this.setVhProperty();
    this.setState({ sHeight: window.innerHeight });
    this.setState({ sWidth: window.innerWidth });
  }
  setVhProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    console.log(vh);
  }
  closeChat() {
    const hist = useHistory();
    hist.push("/");
  }
  render() {
    return (
      <>
        <div style={{ width: this.state.sWidth, height: this.state.sHeight }}>
          <Connect closeChat={this.closeChat} />
        </div>
      </>
    );
  }
}
export default ConnectPage;
