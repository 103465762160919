import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CommonTermsList } from "../common/CommonLists";
import { FiInfo, FiArrowDown } from "react-icons/fi";

const CalculatorPreQualify = () => {
  const [annualIncome, setAnnualIncome] = useState(75000); // Default Value
  const [monthlyDebts, setMonthlyDebts] = useState(500); // Default Value
  const [downPayment, setDownPayment] = useState(20000); // Default Value
  const [loanTerm, setLoanTerm] = useState(30); // Default Value
  const [interestRate, setInterestRate] = useState(5.5); // Default Value
  const [qualifyingAmount, setQualifyingAmount] = useState(null);
  const [debtToIncomeRatio, setDebtToIncomeRatio] = useState(null);

  const handleCalculate = () => {
    const income = parseFloat(annualIncome);
    const debts = parseFloat(monthlyDebts);
    const down = parseFloat(downPayment);
    const rate = parseFloat(interestRate) / 100 / 12;
    const term = loanTerm * 12;

    if (isNaN(income) || isNaN(debts) || isNaN(down) || isNaN(rate)) {
      alert("Please enter valid numbers in all fields.");
      return;
    }

    const maxMonthlyPayment = (income / 12) * 0.43 - debts;
    const loanAmount =
      (maxMonthlyPayment * (1 - Math.pow(1 + rate, -term))) / rate;
    const totalAmount = loanAmount + down;

    const calculatedDTI = ((debts / (income / 12)) * 100).toFixed(2);

    setQualifyingAmount(totalAmount.toFixed(2));
    setDebtToIncomeRatio(calculatedDTI);
  };
  const terms = CommonTermsList;
  return (
    <div className="container">
      <div className="row row--35 align-items-start">
        <div className="col-lg-6 order-2 order-lg-1">
          <div className="section-title text-left mb--10">
            <h3 className="title">Pre-Qualify Calculator</h3>
            <p className="description">
              Use our Pre-Qualify Calculator to estimate the mortgage amount you
              may qualify for based on your income, debts, and financial
              details. Quickly determine your buying power and prepare for your
              home loan journey with confidence.
            </p>
          </div>
          <Box sx={{ padding: "20px", maxWidth: 600, margin: "0 auto" }}>
            <TextField
              label="Annual Income ($)"
              type="number"
              value={annualIncome}
              onChange={(e) => setAnnualIncome(e.target.value)}
              fullWidth
              margin="normal"
              helperText="Estimated yearly gross income."
            />

            <TextField
              label="Monthly Debts ($)"
              type="number"
              value={monthlyDebts}
              onChange={(e) => setMonthlyDebts(e.target.value)}
              fullWidth
              margin="normal"
              helperText="Include credit card payments, car loans, etc."
            />

            <TextField
              label="Down Payment ($)"
              type="number"
              value={downPayment}
              onChange={(e) => setDownPayment(e.target.value)}
              fullWidth
              margin="normal"
              helperText="Initial payment made towards the property."
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Loan Term (Years)</InputLabel>
              <Select
                value={loanTerm}
                onChange={(e) => setLoanTerm(e.target.value)}
              >
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Interest Rate (%)"
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              fullWidth
              margin="normal"
              helperText="Annual interest rate for the loan."
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCalculate}
                fullWidth
                sx={{ mr: 1 }}
              >
                Calculate
              </Button>
              <Tooltip
                title="This calculator provides an estimate based on your input and does not constitute financial advice."
                placement="right"
              >
                <InfoOutlinedIcon color="action" />
              </Tooltip>
            </Box>

            {qualifyingAmount && (
              <Box mt={4}>
                <Typography variant="h6" align="center">
                  You may qualify for a mortgage up to: ${qualifyingAmount}
                </Typography>
                <Typography variant="subtitle1" align="center" sx={{ mt: 1 }}>
                  Debt-to-Income Ratio: {debtToIncomeRatio}%
                </Typography>
              </Box>
            )}
          </Box>
        </div>
        <div className="col-lg-6 order-1 order-lg-2">
          <h4>Key Terms</h4>
          <div>
            {terms.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<FiArrowDown />}>
                  <Typography>{item.term}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.details}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorPreQualify;
