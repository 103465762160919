import React, { Component } from "react";
import { db, auth } from "../config/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import waitIcon from "../../assets/images/icons/ani-agent.gif";
import "./connect.css";

class LeadWait extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadAccepted: false,
      leadExpired: false,
    };
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    this.listenForLeadStatus();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  handleCallback() {
    console.log("Call me back");
    const { closeChat } = this.props;
    auth
      .signOut()
      .then(() => {
        console.log("User signed out.");
        closeChat(); // Close the chat
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  }
  handleWait() {
    console.log("I can wait");
  }

  // Set up a listener for lead status updates
  listenForLeadStatus() {
    const { leadID } = this.props;
    if (leadID) {
      const leadRef = doc(db, "leads", leadID);
      this.unsubscribe = onSnapshot(leadRef, (docSnapshot) => {
        if (docSnapshot.exists) {
          const leadData = docSnapshot.data();
          const { status, assignedAgent } = leadData;
          if (status === "accepted") {
            this.setState({ leadAccepted: true, leadExpired: false });
            this.props.onStatusUpdate(true, assignedAgent);
          } else if (status === "expired") {
            this.setState({ leadExpired: true, leadAccepted: false });
          }
          console.log(this.state.leadAccepted);
        }
      });
    }
  }

  render() {
    const { leadAccepted, leadExpired } = this.state;

    return (
      <div className="lead-wait">
        <h4 className="lead-wait-header">Connecting...</h4>

        {leadAccepted ? (
          <p>Agent connected</p>
        ) : leadExpired ? (
          <div>
            <p className="lead-wait-section">
              All our loan consultants are currently helping other customers.
              Would you like to wait or have someone call you within an hour?
            </p>
            <div className="pt-4 text-center">
              <button
                className="rn-button-style--2 btn-solid rn-btn-small"
                type="button"
                value="Call me back"
                name="btnCallback"
                id="btnCallback"
                onClick={this.handleCallback}
              >
                Call me back
              </button>
            </div>
            <div className="pt-4 text-center">
              <button
                className="rn-button-style--3 btn-solid rn-btn-small"
                type="button"
                value="Wait"
                name="btnCallback"
                id="btnCallback"
                onClick={this.handleWait}
              >
                I can wait
              </button>
            </div>
          </div>
        ) : (
          <div className="lead-wait-section">
            <div className="lead-wait-icon">
              <span role="img" aria-label="waiting">
                <img src={waitIcon} className="animated-icon" />
              </span>
            </div>
            <p>Waiting for the loan consultant...</p>
            <div className="text-small">(average wait time: 2 minutes)</div>
          </div>
        )}
      </div>
    );
  }
}

export default LeadWait;
