import { db, auth, storage } from "./firebase";
import {
  getDocs,
  doc,
  collection,
  writeBatch,
  setDoc,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  Timestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const registerLead = async (objLead) => {
  try {
    const objChat = {
      participants: [objLead.UID],
      chatTitle: objLead.fullName,
      phoneCall: false,
      createdDate: objLead.created,
      phoneNumber: objLead.phone,
      zipCode: objLead.zipCode,
      email: objLead.email,
      status: "active",
    };
    // Create a new group chat with the selected users and chat title
    const chatRef = await addDoc(collection(db, "groupChats"), objChat);
    var uInfo = { ...objLead, chatId: chatRef.id, agents: [] };
    const docRef = await addDoc(collection(db, "leads"), uInfo);
    var leadInfo = { chatId: chatRef.id, leadId: docRef.id };
    return leadInfo;
  } catch (error) {
    console.error("Error registering user:", error.message);
    return null;
  }
};

export const createGroupChat = async (userInfo) => {
  try {
    // console.log(userInfo);
    const objChat = {
      participants: [userInfo.UID],
      chatTitle: userInfo.fullName,
      phoneNumber: userInfo.phone,
      phoneCall: false,
      createdDate: userInfo.created,
      status: "active",
    };
    // Create a new group chat with the selected users and chat title
    const docRef = await addDoc(collection(db, "groupChats"), objChat);
    // console.log("Group chat id: " + docRef.id);
  } catch (error) {
    console.error("Error creating group chat:", error);
    return null;
  }
};
export const addClientMessage = async (objItem) => {
  try {
    //const userID = auth.currentUser.uid;
    //const groupChatId = await getGroupChatId();
    console.log(objItem);
    const collPath = "messages";
    var msgTimestamp = Timestamp.fromDate(new Date());
    const objMsg = {
      chatId: objItem.chatId,
      sender: objItem.sender,
      message: objItem.message,
      timestamp: msgTimestamp,
    };
    // Create a new group chat with the selected users and chat title
    const docRef = await addDoc(collection(db, collPath), objMsg);

    //Update latest message in group_chats
    const gcRef = doc(db, "groupChats", objMsg.chatId);
    await updateDoc(gcRef, {
      latestMessage: objMsg.message,
      latestMessageTimestamp: msgTimestamp,
    });
  } catch (error) {
    console.error("Error creating group chat:", error);
    return null;
  }
};

export const registerAgent = async (userInfo, avatarFile) => {
  try {
    const storageRef = ref(storage, `/agentImages/${userInfo.phone}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, avatarFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          console.log(url);
          userInfo.avatarUrl = url;
          //const docRef = await addDoc(collection(db, "loanOfficers"), userInfo);
          await setDoc(doc(db, "loanOfficers", userInfo.UID), userInfo)
            .then((docRef) => {
              console.log("Document written with ID: ", docRef);
              return true;
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              return false;
            });
        });
      }
    );
  } catch (error) {
    console.error("Error registering user:", error.message);
    return null;
  }
};

export const getGroupChatId = async () => {
  const userID = auth.currentUser.uid;
  //console.log("user ID: " + userID);
  const collRef = collection(db, "leads");
  const queryRef = query(
    collRef,
    where("UID", "==", userID),
    orderBy("created", "desc"),
    limit(1)
  );
  return await getDocs(queryRef)
    .then((querySnapshot) => {
      // total matched documents
      const matchedDocs = querySnapshot.size;
      // console.log("found: " + matchedDocs);
      // querySnapshot.forEach((doc) => {
      //   console.log(doc.id, " => ", doc.data());
      // });
      if (matchedDocs) {
        const chatID = querySnapshot.docs[0].data().chatId;
        //console.log(chatID);
        return chatID;
      } else {
        console.log("0 documents matched the query");
        return null;
      }
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      return null;
    });
};

export const getAgentDetails = async (agentID) => {
  try {
    // Reference the agent document by agentID
    const agentRef = doc(db, "loanOfficers", agentID);

    // Get the document
    const agentDoc = await getDoc(agentRef);

    if (agentDoc.exists()) {
      // Document data if exists
      return agentDoc.data();
    } else {
      console.log("No such agent document!");
      return null;
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const contactUsEntry = async (objItem) => {
  try {
    const collPath = "contactUs";
    var docTimestamp = Timestamp.fromDate(new Date());
    const docRef = await addDoc(collection(db, collPath), objItem);
    console.log("entry saved");
  } catch (error) {
    console.error("Error creating document:", error);
    return null;
  }
};

export const getBlogList = async () => {
  const blogPosts = [
    {
      title:
        "Pre-Qualify for a Mortgage: What You Need to Know Before You Apply",
      date: new Date("2023-04-20"),
      contributor: "Michael Lee",
      keywords: [
        "pre-qualify mortgage",
        "home buying tips",
        "loan pre-qualification",
      ],
      description:
        "Understand the pre-qualification process and its importance in your home buying journey.",
      category: "Mortgage",
      content: `
        <p>
          Embarking on the journey to homeownership is exciting, and a crucial first step is to <strong>pre-qualify for a mortgage</strong>. This process helps you determine your buying power, giving you a clear idea of what homes you can afford. Here's what you need to know:
        </p>
        <h3>What is Pre-Qualification?</h3>
        <p>
          Pre-qualification is an initial assessment of your financial health, providing an estimate of the loan amount you might qualify for. Unlike pre-approval, it doesn't involve a detailed credit check. Instead, it’s based on self-reported income, debts, and assets.
        </p>
        <h3>Required Information</h3>
        <ul>
          <li><strong>Annual Income:</strong> Your gross yearly income before taxes.</li>
          <li><strong>Monthly Debts:</strong> Includes credit card payments, car loans, and other recurring obligations.</li>
          <li><strong>Down Payment:</strong> The amount you can contribute upfront.</li>
        </ul>
        <p>
          For example, if your annual income is $80,000 and your monthly debts are $500, you may pre-qualify for a loan amount around $300,000, depending on the interest rate and term.
        </p>
        <h3>Benefits of Pre-Qualification</h3>
        <p>
          Pre-qualifying gives you a competitive edge when house hunting. Sellers and agents take your offer more seriously, knowing you’ve taken the first step toward securing financing. Additionally, it helps narrow down your home search to properties within your budget.
        </p>
        <p>
          Ready to get started? Use our <strong>Pre-Qualify Calculator</strong> or <strong>connect with a loan consultant</strong> today for personalized advice.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/real-estate-agent-holding-house-model-260nw-1689645935.jpg",
    },
    {
      title: "How to Choose the Right Loan Consultant for Your Needs",
      date: new Date("2023-03-12"),
      contributor: "Sarah Johnson",
      keywords: [
        "loan consultant tips",
        "mortgage advisor",
        "choosing a loan expert",
      ],
      description:
        "Learn how to find the best loan consultant who can guide you through your mortgage journey.",
      category: "Mortgage",
      content: `
        <p>
          Choosing the right loan consultant can make all the difference in your mortgage journey. With so many options available, how do you find the one best suited to your needs? Here’s a guide to help you make an informed decision:
        </p>
        <h3>1. Look for Experience and Credentials</h3>
        <p>
          Start by checking the consultant’s qualifications. Are they licensed? Do they have experience in your local market? An experienced consultant will be well-versed in local housing trends and mortgage options.
        </p>
        <h3>2. Prioritize Personalized Service</h3>
        <p>
          Mortgage solutions are not one-size-fits-all. Look for a consultant who takes the time to understand your financial goals and provides tailored advice. Personalized service ensures that you get loan options that align with your needs.
        </p>
        <h3>3. Read Reviews and Ask for Recommendations</h3>
        <p>
          Online reviews and testimonials can provide insights into a consultant's reputation. Additionally, ask friends or family for referrals to trusted consultants they’ve worked with.
        </p>
        <h3>4. Communication is Key</h3>
        <p>
          A good loan consultant should be responsive and willing to answer your questions. Clear communication helps you stay informed throughout the mortgage process, reducing stress and confusion.
        </p>
        <p>
          <strong>Need help finding the right loan consultant?</strong> Connect with one of our certified professionals today and get expert guidance tailored to your unique situation.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/loan-agent-discussing-options-client-260nw-1542247158.jpg",
    },
    {
      title:
        "Breaking Down Mortgage Costs: What First-Time Buyers Need to Know",
      date: new Date("2023-02-05"),
      contributor: "Emily Davis",
      keywords: [
        "mortgage costs",
        "first-time homebuyer tips",
        "understanding mortgage expenses",
      ],
      description:
        "Discover the key costs involved in securing a mortgage, from down payments to closing fees.",
      category: "Mortgage",
      content: `
        <p>
          Buying your first home is an exciting milestone, but it’s essential to understand the full scope of mortgage costs beyond just the monthly payments. Here’s a breakdown of the key expenses you’ll encounter:
        </p>
        <h3>1. Down Payment</h3>
        <p>
          The down payment is the upfront amount you contribute toward the home's purchase price. It typically ranges from 3% to 20%, depending on the loan type. For example, on a $250,000 home, a 10% down payment would be $25,000.
        </p>
        <h3>2. Closing Costs</h3>
        <p>
          These are fees associated with finalizing your mortgage. They typically include appraisal fees, title insurance, and lender fees, averaging 2% to 5% of the loan amount. For a $250,000 loan, expect to pay around $5,000 to $12,500 in closing costs.
        </p>
        <h3>3. Private Mortgage Insurance (PMI)</h3>
        <p>
          If your down payment is less than 20%, you may need to pay PMI. This insurance protects the lender and usually costs between 0.5% to 1% of the loan amount annually.
        </p>
        <h3>4. Property Taxes and Homeowners Insurance</h3>
        <p>
          These are ongoing expenses included in your monthly mortgage payment. Property taxes vary by location, while homeowners insurance protects your investment against potential risks.
        </p>
        <p>
          Understanding these costs is crucial for budgeting and avoiding surprises during the home-buying process. <strong>Talk to a loan consultant</strong> today to get a detailed estimate and personalized guidance.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/paper-house-calculator-keys-concept-260nw-1586256095.jpg",
    },
    {
      title:
        "Understanding Debt-to-Income Ratio and Its Impact on Your Mortgage",
      date: new Date("2023-01-15"),
      contributor: "Michael Carter",
      keywords: [
        "debt-to-income ratio",
        "mortgage qualification",
        "DTI calculator",
      ],
      description:
        "Learn the importance of Debt-to-Income (DTI) ratio in mortgage qualification and how to improve it.",
      category: "Mortgage",
      content: `
        <p>
          When applying for a mortgage, lenders evaluate several factors to determine your eligibility. One critical metric is your <strong>Debt-to-Income (DTI) ratio</strong>. But what exactly is DTI, and why does it matter?
        </p>
        <h3>What is Debt-to-Income Ratio?</h3>
        <p>
          DTI is a percentage that compares your monthly debt payments to your gross monthly income. Lenders use it to assess your ability to manage monthly payments and repay borrowed money. A lower DTI indicates better financial health.
        </p>
        <h3>How to Calculate Your DTI</h3>
        <p>
          The formula for calculating DTI is simple:
        </p>
        <blockquote>
          <strong>DTI = (Total Monthly Debt Payments / Gross Monthly Income) x 100</strong>
        </blockquote>
        <p>
          For instance, if your monthly debts total $1,500 and your gross income is $5,000, your DTI would be 30%.
        </p>
        <h3>Why DTI Matters</h3>
        <p>
          Most lenders prefer a DTI ratio of 43% or lower. A lower DTI improves your chances of qualifying for a mortgage with favorable terms. It also ensures you don’t overextend financially, keeping your monthly payments manageable.
        </p>
        <h3>Tips to Improve Your DTI</h3>
        <ul>
          <li>Pay down existing debts like credit cards and personal loans.</li>
          <li>Avoid taking on new debt before applying for a mortgage.</li>
          <li>Consider increasing your income through side jobs or other sources.</li>
        </ul>
        <p>
          Ready to explore your mortgage options? <strong>Talk to a loan consultant today</strong> for personalized advice and to better understand how your DTI impacts your eligibility.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/financial-concept-dti-ratio-wooden-blocks-260nw-1773845957.jpg",
    },
    {
      title: "Refinancing Myths Debunked: What You Should Really Know",
      date: new Date("2023-02-08"),
      contributor: "Lisa Moore",
      keywords: ["refinancing myths", "refinance mortgage", "mortgage tips"],
      description:
        "Address common misconceptions about refinancing and learn the real facts about its benefits and costs.",
      category: "Refinance",
      content: `
        <p>
          Refinancing your mortgage can seem daunting, especially with the many myths surrounding it. Let's debunk some of the most common misconceptions and help you make informed decisions.
        </p>
        <h3>Myth 1: Refinancing Always Costs Too Much</h3>
        <p>
          While refinancing involves closing costs, these are often outweighed by the long-term savings. For instance, lowering your interest rate by 1% on a $300,000 loan can save you thousands over the loan term.
        </p>
        <h3>Myth 2: You Need Perfect Credit to Refinance</h3>
        <p>
          While a higher credit score can get you better rates, many lenders offer refinancing options for those with less-than-perfect credit. FHA Streamline Refinancing, for example, has more lenient requirements.
        </p>
        <h3>Myth 3: Refinancing Takes Too Long</h3>
        <p>
          Advances in technology have streamlined the refinancing process. Many applications can be completed online, reducing the time needed to close.
        </p>
        <p>
          Don’t let myths hold you back. <strong>Connect with a loan consultant today</strong> to learn how refinancing can benefit you.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/myth-word-written-on-wooden-260nw-1811582858.jpg",
    },
    {
      title: "The Role of Your Zip Code in Finding the Best Loan Consultant",
      date: new Date("2023-03-18"),
      contributor: "Emily Johnson",
      keywords: [
        "loan consultant zip code",
        "local mortgage experts",
        "mortgage advice",
      ],
      description:
        "Understand why providing your zip code helps you connect with the best local loan consultants.",
      category: "Mortgage",
      content: `
        <p>
          When applying for a mortgage, local expertise matters. That’s why we ask for your zip code to connect you with the right loan consultant in your area.
        </p>
        <h3>Why Zip Code Matters</h3>
        <p>
          Housing markets vary significantly by location. A consultant familiar with your area can provide insights into local market trends, property values, and regional lending options.
        </p>
        <h3>Personalized Loan Advice</h3>
        <p>
          Local consultants understand state-specific regulations and can recommend loan programs tailored to your circumstances, such as first-time homebuyer incentives.
        </p>
        <p>
          Ready to get started? <strong>Provide your zip code today</strong> and connect with an expert who understands your market.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/pin-location-on-zip-code-map-260nw-1694192379.jpg",
    },
    {
      title:
        "How to Prepare for Your First Conversation with a Loan Consultant",
      date: new Date("2023-01-30"),
      contributor: "David Clarke",
      keywords: [
        "loan consultant meeting",
        "mortgage preparation",
        "loan consultation tips",
      ],
      description:
        "Get ready for your first meeting with a loan consultant with this step-by-step preparation guide.",
      category: "Mortgage",
      content: `
        <p>
          Meeting with a loan consultant is a crucial step in your mortgage journey. Here’s how to prepare for a productive conversation:
        </p>
        <h3>1. Gather Your Financial Documents</h3>
        <p>
          Bring recent pay stubs, tax returns, and bank statements. These help the consultant assess your financial health and recommend suitable loan options.
        </p>
        <h3>2. Know Your Credit Score</h3>
        <p>
          A good credit score improves your chances of securing favorable terms. Obtain a free credit report before your meeting.
        </p>
        <p>
          Want more tips? <strong>Contact our loan consultants</strong> for personalized guidance.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/person-preparing-financial-documents-loan-meeting-260nw-1728482839.jpg",
    },
    {
      title:
        "Free Mortgage Tools: Maximize Your Home Buying Journey with Our Calculators",
      date: new Date("2023-04-05"),
      contributor: "Laura Adams",
      keywords: [
        "mortgage calculators",
        "home buying tools",
        "loan calculator",
      ],
      description:
        "Discover our free mortgage tools designed to help you make smarter financial decisions.",
      category: "Tools",
      content: `
        <p>
          Navigating the mortgage process can be overwhelming. That’s why we offer free tools to simplify your journey:
        </p>
        <h3>1. Mortgage Calculator</h3>
        <p>
          Estimate your monthly payments based on loan amount, term, and interest rate.
        </p>
        <h3>2. Refinance Calculator</h3>
        <p>
          Determine if refinancing could save you money.
        </p>
        <p>
          Try our <strong>free calculators</strong> today and take control of your financial future.
        </p>
      `,
      image:
        "https://www.shutterstock.com/image-photo/financial-tools-calculator-mortgage-home-buying-260nw-1744861135.jpg",
    },
  ];
  try {
    const collectionRef = collection(db, "blogPosts"); // Replace "blogPosts" with your Firestore collection name

    for (let post of blogPosts) {
      await addDoc(collectionRef, post);
      console.log(`Blog post "${post.title}" added successfully.`);
    }
    console.log("All blog posts inserted successfully.");
  } catch (error) {
    console.error("Error inserting blog post:", error);
    return null;
  }
};
