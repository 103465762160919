import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiArrowDown, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FAQList } from "../component/common/CommonLists";
import consultant from "../assets/images/about/about-3.jpg";
import { Redirect } from "react-router-dom";
import ConnectWrapper from "../component/connect/ConnectWrapper";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      redirect: false,
    };
    this.chatRef = React.createRef(); // Create a ref
    this.handleShowChat = this.handleShowChat.bind(this);
  }
  componentDidMount() {
    const isMobile =
      /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop|Mobile/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      this.setState({ isMobile: true });
    }
  }
  handleShowChat() {
    if (this.state.isMobile) {
      this.setState({ redirect: true });
    }
    if (this.chatRef.current) {
      this.chatRef.current.openChat(); // Call the child method via the ref
    }
  }
  render() {
    const { isMobile, redirect } = this.state;
    // If redirect is true, navigate to the new page
    if (redirect) {
      return <Redirect to="/connect" />;
    }
    const faqData = FAQList;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="FAQs" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"FAQs"} />
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--10">
                  <h2 className="title">Got Questions? We’ve Got Answers</h2>
                </div>
                <Box mt={3}>
                  {faqData.map((item, index) => (
                    <Accordion key={index} sx={{ mb: 2 }}>
                      <AccordionSummary expandIcon={<FiArrowDown />}>
                        <Typography variant="h6">{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
                <center className="about-button mt--50">
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="button"
                    onClick={this.handleShowChat}
                  >
                    Connect Now to Get Started
                  </button>
                </center>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumb position-relative">
                  <img
                    className="w-100"
                    src={consultant}
                    alt="Service Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Page Area  */}

        {/* Start connect button */}
        <ConnectWrapper ref={this.chatRef} isMobile={isMobile} />
        {/* End connect button */}
        {/* Start Footer Style  */}
        <Footer onChildAction={this.handleShowChat} isMobile={isMobile} />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default FAQ;
