import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import Helmet from "../component/common/Helmet";

import benefits from "../assets/images/home/loandeer-benefits.jpg";
import consultant from "../assets/images/about/about-3.jpg";

import ConnectWrapper from "../component/connect/ConnectWrapper";
import { FAQList } from "../component/common/CommonLists";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BLogList from "../elements/blog/BlogList";
import NewsList from "../elements/service/NewsList";

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--17",
    category: "",
    title: "Need a pre-approval right now?",
    description:
      "Don’t miss out! Chat live with our loan experts today and get personalized loan advice.",
    buttonText: "Connect Now!",
    buttonLink: "/contact",
  },
  // {
  //   textPosition: "text-left",
  //   bgImage: "bg_image--18",
  //   category: "",
  //   title: "Lower Your Payments with Expert Help!",
  //   description:
  //     "Ready to lower your mortgage payments? Our consultants are just one chat away!",
  //   buttonText: "Connect Now!",
  //   buttonLink: "/contact",
  // },
  {
    textPosition: "text-left",
    bgImage: "bg_image--19",
    category: "",
    title: "Does it make sense to refinance right now?",
    description:
      "Take 5 minutes to speak with our mortgage experts and get personalized refinancing options. No obligations.",
    buttonText: "Connect Now!",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--19",
    category: "",
    title: "Think You Got the Best Mortgage Deal? Let's Double-Check!",
    description:
      "Already signed a mortgage application? Compare rates and terms to ensure you got the best mortgage deal possible before finalizing.",
    buttonText: "Connect Now!",
    buttonLink: "/contact",
  },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      redirect: false,
    };
    this.chatRef = React.createRef(); // Create a ref
    this.handleShowChat = this.handleShowChat.bind(this);
  }
  componentDidMount() {
    const isMobile =
      /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop|Mobile/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      this.setState({ isMobile: true });
    }
  }
  handleShowChat() {
    if (this.state.isMobile) {
      this.setState({ redirect: true });
    }
    if (this.chatRef.current) {
      this.chatRef.current.openChat(); // Call the child method via the ref
    }
  }

  render() {
    const PostList = BlogContent.slice(0, 3);
    const faqList = FAQList.slice(0, 3);
    const { isMobile, redirect } = this.state;
    // If redirect is true, navigate to the new page
    if (redirect) {
      return <Redirect to="/connect" />;
    }
    return (
      <Fragment>
        <Helmet pageTitle="LoanDeer" />
        <Header />
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="8"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText && !isMobile ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href="#"
                                onClick={this.handleShowChat}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}
        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h2 className="title">Real Experts. Real Answers.</h2>
                          <p>
                            Connect with a live, certified loan consultant in
                            your area for real-time, personalized mortgage
                            advice—no chatbots, just real experts.
                          </p>
                          <h4 className="title">Our Working Process</h4>
                          <ul className="liststyle">
                            <li>
                              <strong>Personalized Advice: </strong>
                              Every homebuyer’s situation is unique. Get
                              tailored mortgage solutions from an expert who
                              understands your needs.
                            </li>
                            <li>
                              <strong>Faster Answers: </strong>Skip the
                              automated responses. Get real-time, human advice
                              to move forward quickly.
                            </li>
                            <li>
                              <strong>Local Expertise: </strong>Connect with
                              certified consultants who know your local market
                              and can guide you through state-specific
                              requirements.
                            </li>
                          </ul>
                          <h4 className="title">
                            Get Started in 3 Simple Steps
                          </h4>
                          <ul className="liststyle">
                            <li>
                              <strong>Step 1: </strong>
                              Click the "Connect" button to start a chat with a
                              live consultant.
                            </li>
                            <li>
                              <strong>Step 2: </strong>Share your goals –
                              whether you're buying, refinancing, or just
                              exploring options.
                            </li>
                            <li>
                              <strong>Step 3: </strong>Receive expert guidance
                              and personalized loan solutions.
                            </li>
                          </ul>
                          <center className="about-button mt--50">
                            <button
                              className="rn-button-style--2 btn-solid"
                              type="button"
                              onClick={this.handleShowChat}
                            >
                              Connect Now to Get Started
                            </button>
                          </center>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={consultant}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}
        {/* Start Questions Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img className="w-85" src={benefits} alt="About Images" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Benefits of Working with Us</h2>
                    <p>Your Trusted Partner in Mortgage and Loan Consulting.</p>
                  </div>
                  <div className="mt--30">
                    <ul className="list-style--1">
                      <li>
                        <FiCheck />{" "}
                        <strong>Wide Network of Certified Consultants:</strong>{" "}
                        Access a network of professionals with proven expertise.
                      </li>
                      <li>
                        <FiCheck /> <strong>Fast, Reliable Support:</strong>{" "}
                        Connect with the next available consultant in real-time.
                      </li>
                      <li>
                        <FiCheck />{" "}
                        <strong>No Obligation, Just Guidance:</strong> Our goal
                        is to help you make informed decisions.
                      </li>
                    </ul>

                    {/* <Accordion01 /> */}
                  </div>
                  <div className="about-button mt--50">
                    <button
                      className="rn-button-style--2 btn-solid"
                      type="button"
                      onClick={this.handleShowChat}
                    >
                      Start now!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Questions Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-12 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Got Questions? We’ve Got Answers</h2>
                    <p className="description">
                      Find answers to common questions about connecting with our
                      certified loan consultants. Learn how quickly you can get
                      expert mortgage advice, what information you need to
                      start, and why our service is always free.
                    </p>
                  </div>
                  <div className="mt--30">
                    <Box mt={3}>
                      {faqList.map((item, index) => (
                        <Accordion key={index} sx={{ mb: 2 }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">
                              {item.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{item.answer}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </div>
                  <div className="mt--30 text-right">
                    Visit our <a href="/faqs">FAQs</a> page for more answers.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        {/* Start Featured Consultants Area */}
        <div className="rn-brand-area bg_color--5 ptb--60">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="text-center mb_sm--0">
                  <h4>Meet Our Certified Loan Consultants in Your Area</h4>
                  <BrandTwo />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Featured calculators  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Featured Calculators</h2>
                  <p>
                    Explore our powerful calculators to make informed financial
                    decisions with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Featured calculators  */}

        {/* Start blogs Area */}
        <div className="portfolio-area ptb--120 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Our Blogs</h2>
                    <p>
                      Explore our insightful blog to stay informed about
                      mortgages, refinancing, and home-buying tips. Discover
                      expert advice, tools, and resources to guide you through
                      every step of your financial journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <BLogList limit="6" />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 text-center">
                    <a className="rn-button-style--2 btn-solid" href="/blog">
                      <span>View More Blogs</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start FNews Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Latest News</h2>
                  <p>
                    Explore the latest trends, updates, and insights on mortgage
                    rates, refinancing, and the housing market from trusted
                    sources.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <NewsList
                  item="3"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End News Area  */}

        {/* Start connect button */}
        <ConnectWrapper ref={this.chatRef} isMobile={isMobile} />
        {/* End connect button */}
        {/* Start Footer Style  */}
        <Footer onChildAction={this.handleShowChat} isMobile={isMobile} />
        {/* End Footer Style  */}
      </Fragment>
    );
  }
}
export default Home;
