import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiArrowDown, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FAQList } from "../component/common/CommonLists";
import consultant from "../assets/images/about/about-3.jpg";
import { Box, Typography } from "@mui/material";

class PrivacyPolicy extends Component {
  render() {
    const faqData = FAQList;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Privacy Policy" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Privacy Policy</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-12 order-2 order-lg-1">
                <div className="section-title text-left mb--10">
                  <h2 className="title">Our Privacy Policy</h2>
                </div>
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    1. Introduction
                  </Typography>
                  <Typography paragraph>
                    LoanDeer LLC is committed to protecting your privacy. This
                    Privacy Policy explains how we collect, use, and safeguard
                    your personal information when you use our website and
                    services.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    2. Information We Collect
                  </Typography>
                  <Typography paragraph>
                    When you connect with a loan consultant, we collect the
                    following information:
                  </Typography>
                  <ul>
                    <li>Your name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Zip code</li>
                  </ul>

                  <Typography variant="h6" gutterBottom>
                    3. How We Use Your Information
                  </Typography>
                  <Typography paragraph>
                    We use the information we collect for the following
                    purposes:
                  </Typography>
                  <ul>
                    <li>To connect you with a certified loan consultant</li>
                    <li>To personalize your loan recommendations</li>
                    <li>To send you promotional materials</li>
                    <li>
                      To improve our services through training and quality
                      assurance
                    </li>
                  </ul>

                  <Typography variant="h6" gutterBottom>
                    4. Data Sharing and Disclosure
                  </Typography>
                  <Typography paragraph>
                    We do not sell your personal information to third parties.
                    However, we may share your information with:
                  </Typography>
                  <ul>
                    <li>Certified loan consultants</li>
                    <li>
                      Service providers assisting us in delivering our services
                    </li>
                    <li>Legal or regulatory authorities, if required by law</li>
                  </ul>

                  <Typography variant="h6" gutterBottom>
                    5. Data Security
                  </Typography>
                  <Typography paragraph>
                    We implement robust security measures to protect your
                    personal information. However, no security system is
                    completely foolproof, and we cannot guarantee absolute
                    protection.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    6. Your Rights
                  </Typography>
                  <Typography paragraph>You have the right to:</Typography>
                  <ul>
                    <li>Access your personal information</li>
                    <li>Request corrections or updates</li>
                    <li>Request deletion of your information</li>
                  </ul>
                  <Typography paragraph>
                    To exercise these rights, please contact us at{" "}
                    <a href="mailto:support@LoanDeer.com">
                      support@LoanDeer.com
                    </a>
                    .
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    7. Cookies and Tracking
                  </Typography>
                  <Typography paragraph>
                    Our website uses cookies to enhance your browsing experience
                    and gather usage data. You can manage your cookie
                    preferences through your browser settings.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    8. Changes to This Policy
                  </Typography>
                  <Typography paragraph>
                    We may update this Privacy Policy periodically. Any changes
                    will be posted on this page with an updated effective date.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    9. Contact Us
                  </Typography>
                  <Typography paragraph>
                    If you have questions about this Privacy Policy, contact us
                    at{" "}
                    <a href="mailto:support@LoanDeer.com">
                      support@LoanDeer.com
                    </a>
                    .
                  </Typography>
                </Box>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Page Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PrivacyPolicy;
