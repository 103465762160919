import React, { Component } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FiInfo, FiArrowDown } from "react-icons/fi";
import { RefinanceFaqList } from "../common/CommonLists";

class CalculatorRefinance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLoanAmount: 250000,
      currentInterestRate: 6.5,
      currentLoanTerm: 30,
      newInterestRate: 4.5,
      newLoanTerm: 30,
      closingCosts: 5000,
      currentMonthlyPayment: 0,
      newMonthlyPayment: 0,
      monthlySavings: 0,
      breakEvenMonths: 0,
      totalInterestSavings: 0,
    };
  }

  componentDidMount() {
    this.calculateCurrentLoan();
    this.calculateRefinance();
  }

  calculateCurrentLoan = () => {
    const { currentLoanAmount, currentInterestRate, currentLoanTerm } =
      this.state;
    const rate = currentInterestRate / 100 / 12;
    const totalPayments = currentLoanTerm * 12;

    const currentMonthlyPayment =
      (currentLoanAmount * rate) / (1 - Math.pow(1 + rate, -totalPayments));
    this.setState({ currentMonthlyPayment: currentMonthlyPayment.toFixed(2) });
  };

  calculateRefinance = () => {
    const {
      currentLoanAmount,
      newInterestRate,
      newLoanTerm,
      closingCosts,
      currentMonthlyPayment,
    } = this.state;
    const rate = newInterestRate / 100 / 12;
    const totalPayments = newLoanTerm * 12;

    const newMonthlyPayment =
      (currentLoanAmount * rate) / (1 - Math.pow(1 + rate, -totalPayments));
    const monthlySavings = currentMonthlyPayment - newMonthlyPayment;
    const breakEvenMonths = closingCosts / monthlySavings;
    const totalInterestSavings = (
      currentMonthlyPayment * totalPayments -
      newMonthlyPayment * totalPayments
    ).toFixed(2);

    this.setState({
      newMonthlyPayment: newMonthlyPayment.toFixed(2),
      monthlySavings: monthlySavings.toFixed(2),
      breakEvenMonths: breakEvenMonths.toFixed(2),
      totalInterestSavings,
    });
  };

  handleInputChange = (field) => (event) => {
    this.setState({ [field]: event.target.value }, () => {
      this.calculateCurrentLoan();
      this.calculateRefinance();
    });
  };
  formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  };
  render() {
    const {
      currentLoanAmount,
      currentInterestRate,
      currentLoanTerm,
      newInterestRate,
      newLoanTerm,
      closingCosts,
      currentMonthlyPayment,
      newMonthlyPayment,
      monthlySavings,
      breakEvenMonths,
      totalInterestSavings,
    } = this.state;
    const FAQs = RefinanceFaqList;
    return (
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--10">
              <h3 className="title">Refinance Calculator</h3>
              <p className="description">
                Use our Refinance Calculator to explore how refinancing your
                mortgage could lower your monthly payments and save you
                thousands in interest. Instantly calculate your potential
                savings, break-even point, and new payment schedule to make
                informed decisions about your financial future.
              </p>
            </div>
            <Box sx={{ padding: 2, maxWidth: 600, margin: "0 auto" }}>
              <Box mt={2}>
                <Typography variant="h6">Current Loan Details</Typography>
                <TextField
                  label="Loan Amount"
                  type="number"
                  value={currentLoanAmount}
                  onChange={this.handleInputChange("currentLoanAmount")}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Current Interest Rate (%)"
                  type="number"
                  value={currentInterestRate}
                  onChange={this.handleInputChange("currentInterestRate")}
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Current Loan Term (Years)</InputLabel>
                  <Select
                    value={currentLoanTerm}
                    onChange={this.handleInputChange("currentLoanTerm")}
                  >
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box mt={2}>
                <Typography variant="h6">Refinance Details</Typography>
                <TextField
                  label="New Interest Rate (%)"
                  type="number"
                  value={newInterestRate}
                  onChange={this.handleInputChange("newInterestRate")}
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>New Loan Term (Years)</InputLabel>
                  <Select
                    value={newLoanTerm}
                    onChange={this.handleInputChange("newLoanTerm")}
                  >
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Closing Costs"
                  type="number"
                  value={closingCosts}
                  onChange={this.handleInputChange("closingCosts")}
                  fullWidth
                  margin="normal"
                />
              </Box>

              <Box mt={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mt: 4 }}
                >
                  <Typography variant="h6">Results</Typography>
                  <Tooltip
                    title="This calculator provides an estimate based on your input and does not constitute financial advice."
                    placement="right"
                  >
                    <InfoOutlinedIcon color="action" />
                  </Tooltip>
                </Box>

                <Typography>
                  Current Monthly Payment:{" "}
                  {this.formatCurrency(currentMonthlyPayment)}
                </Typography>
                <Typography>
                  New Monthly Payment: {this.formatCurrency(newMonthlyPayment)}
                </Typography>
                <Typography>
                  Monthly Savings: {this.formatCurrency(monthlySavings)}
                </Typography>
                <Typography>
                  Break-Even Period: {breakEvenMonths} months
                </Typography>
                <Typography>
                  Total Interest Savings:{" "}
                  {this.formatCurrency(totalInterestSavings)}
                </Typography>
              </Box>
            </Box>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <h4>Common Refinancing Questions</h4>
            <div>
              {FAQs.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<FiArrowDown />}>
                    <Typography>{item.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatorRefinance;
