import React, { Component } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
  Grid,
} from "@mui/material";

class FirstTimeHomebuyerChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedSteps: {},
    };

    this.steps = [
      {
        category: "Financial Preparation",
        items: [
          "Check your credit score and improve it if necessary.",
          "Calculate your budget and determine how much home you can afford.",
          "Save for a down payment (consider 3%-20% of the home price).",
          "Factor in closing costs (typically 2%-5% of the loan amount).",
          "Get pre-approved for a mortgage.",
        ],
      },
      {
        category: "Finding the Right Home",
        items: [
          "Decide on your must-haves (e.g., number of bedrooms, location).",
          "Research neighborhoods and schools.",
          "Hire a reputable real estate agent.",
          "Start attending open houses and home tours.",
        ],
      },
      {
        category: "Making an Offer",
        items: [
          "Work with your agent to determine a competitive offer price.",
          "Submit an offer letter with your terms and contingencies.",
          "Negotiate with the seller if necessary.",
          "Get your offer accepted and sign the purchase agreement.",
        ],
      },
      {
        category: "Securing the Mortgage",
        items: [
          "Submit all required documents for final loan approval.",
          "Lock in your interest rate.",
          "Review your loan estimate and closing disclosure.",
        ],
      },
      {
        category: "Closing the Deal",
        items: [
          "Conduct a home inspection and address any issues.",
          "Order a home appraisal.",
          "Secure homeowner’s insurance.",
          "Perform a final walkthrough of the property.",
          "Sign closing documents and get the keys to your new home!",
        ],
      },
    ];
  }

  handleCheckboxChange = (category, index) => {
    const { completedSteps } = this.state;
    const updatedSteps = { ...completedSteps };

    if (!updatedSteps[category]) {
      updatedSteps[category] = [];
    }

    if (updatedSteps[category].includes(index)) {
      updatedSteps[category] = updatedSteps[category].filter(
        (i) => i !== index
      );
    } else {
      updatedSteps[category].push(index);
    }

    this.setState({ completedSteps: updatedSteps });
  };

  isStepCompleted = (category, index) => {
    const { completedSteps } = this.state;
    return completedSteps[category]?.includes(index);
  };

  getProgress = () => {
    const totalSteps = this.steps.reduce(
      (sum, step) => sum + step.items.length,
      0
    );
    const completedStepsCount = Object.values(this.state.completedSteps).reduce(
      (sum, categorySteps) => sum + categorySteps.length,
      0
    );
    return Math.round((completedStepsCount / totalSteps) * 100);
  };

  render() {
    const progress = this.getProgress();

    return (
      <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h4" align="center" gutterBottom>
          First-Time Homebuyer Checklist Tool
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          A step-by-step guide to help you navigate the home-buying process.
        </Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" align="center">
          Progress: {progress}%
        </Typography>
        <Divider sx={{ my: 3 }} />

        {this.steps.map((step, categoryIndex) => (
          <Box key={categoryIndex} sx={{ marginBottom: "30px" }}>
            <Typography variant="h5" sx={{ marginBottom: "10px" }}>
              {step.category}
            </Typography>
            <Grid container spacing={2}>
              {step.items.map((item, itemIndex) => (
                <Grid item xs={12} key={itemIndex}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.isStepCompleted(step.category, itemIndex)}
                        onChange={() =>
                          this.handleCheckboxChange(step.category, itemIndex)
                        }
                      />
                    }
                    label={item}
                  />
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ my: 2 }} />
          </Box>
        ))}

        {progress === 100 ? (
          <Typography
            variant="h6"
            align="center"
            color="green"
            sx={{ marginTop: "20px" }}
          >
            Congratulations! You’ve completed all the steps to become a
            homeowner.
          </Typography>
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Back to Top
          </Button>
        )}
      </Box>
    );
  }
}

export default FirstTimeHomebuyerChecklist;
