import React, { Component } from "react";
import { FiMessageCircle } from "react-icons/fi";
import Drawer from "@mui/material/Drawer";
import Connect from "./Connect";
import "./connect.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

class ConnectWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openChat = this.openChat.bind(this);
    this.closeChat = this.closeChat.bind(this);
  }
  componentDidMount() {}
  openChat() {
    this.setState({ isOpen: true });
  }
  closeChat() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const { isMobile } = this.props;
    return (
      <div>
        {!isMobile ? (
          <>
            <button
              className="btn btn-primary chat-button"
              type="button"
              onClick={this.openChat}
            >
              <span className="chat-button-text">
                <FiMessageCircle /> Connect
              </span>
            </button>
            <Drawer
              anchor="right"
              open={isOpen}
              onClose={() => this.setState({ isOpen: false })}
            >
              <div
                style={{ position: "relative", height: "100%", width: "400px" }}
              >
                <Connect closeChat={this.closeChat} />
              </div>
            </Drawer>
          </>
        ) : (
          <Link
            className="btn btn-primary chat-button text-white"
            to={{
              pathname: "/connect",
            }}
          >
            {" "}
            <span className="chat-button-text">
              <FiMessageCircle /> Connect
            </span>
          </Link>
        )}
      </div>
    );
  }
}

export default ConnectWrapper;
