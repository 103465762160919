import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiArrowDown, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FAQList } from "../component/common/CommonLists";
import consultant from "../assets/images/about/about-3.jpg";
import { Box, Typography } from "@mui/material";

class Terms extends Component {
  render() {
    const faqData = FAQList;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Terms & conditions" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Terms & Considions</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-12 order-2 order-lg-1">
                <div className="section-title text-left mb--10">
                  <h2 className="title">Our Terms</h2>
                </div>
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    1. Acceptance of Terms
                  </Typography>
                  <Typography paragraph>
                    By accessing or using the LoanDeer LLC website, you agree to
                    comply with and be bound by these Terms & Conditions. These
                    terms govern your use of our services, including the
                    connection with certified loan consultants. If you do not
                    agree to these terms, you should not use our website.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    2. Eligibility
                  </Typography>
                  <Typography paragraph>
                    To use our services, you must be at least 18 years old and
                    capable of entering into a legally binding agreement. By
                    using our website, you confirm that you meet these
                    requirements.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    3. Services Provided
                  </Typography>
                  <Typography paragraph>
                    LoanDeer LLC offers a platform that connects users with
                    certified loan consultants in their area. Our consultants
                    provide personalized mortgage advice, refinancing options,
                    and other loan-related services. All advice and services
                    provided by consultants are for informational purposes only.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    4. User Information and Responsibilities
                  </Typography>
                  <Typography paragraph>
                    When connecting with a loan consultant, you agree to provide
                    accurate and complete information, including your name,
                    email, phone number, and zip code. You are responsible for
                    maintaining the confidentiality of your information and any
                    actions taken using your account.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    5. Use of Information
                  </Typography>
                  <Typography paragraph>
                    By providing your information, you consent to its use for
                    the following purposes:
                  </Typography>
                  <ul>
                    <li>
                      Facilitating your connection with a certified loan
                      consultant
                    </li>
                    <li>Providing tailored loan advice and recommendations</li>
                    <li>Marketing and promotional purposes</li>
                    <li>Training and quality assurance</li>
                  </ul>

                  <Typography variant="h6" gutterBottom>
                    6. Intellectual Property
                  </Typography>
                  <Typography paragraph>
                    All content on the LoanDeer LLC website, including text,
                    graphics, logos, and images, is the property of LoanDeer LLC
                    or its licensors. You may not reproduce, distribute, or use
                    any content without prior written permission.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    7. Limitation of Liability
                  </Typography>
                  <Typography paragraph>
                    LoanDeer LLC is not liable for any direct, indirect,
                    incidental, or consequential damages arising from the use of
                    our website or services. This includes, but is not limited
                    to, errors in advice provided by consultants, system
                    failures, or unauthorized access to your data.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    8. Third-Party Links
                  </Typography>
                  <Typography paragraph>
                    Our website may contain links to third-party websites or
                    services. LoanDeer LLC is not responsible for the content or
                    practices of these third parties. We recommend reviewing
                    their terms and privacy policies.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    9. Changes to Terms
                  </Typography>
                  <Typography paragraph>
                    LoanDeer LLC reserves the right to update or modify these
                    Terms & Conditions at any time. Any changes will be
                    effective immediately upon posting. Continued use of our
                    website indicates acceptance of the updated terms.
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    10. Contact Us
                  </Typography>
                  <Typography paragraph>
                    For any questions or concerns about these Terms &
                    Conditions, please contact us at{" "}
                    <a href="mailto:support@LoanDeer.com">
                      support@LoanDeer.com
                    </a>
                    .
                  </Typography>
                </Box>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Page Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Terms;
