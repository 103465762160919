import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.props.pageTitle} - Live Mortgage Loan Consultants | Refinance
            & New Loan Solutions – Free Consultation
          </title>
          <meta
            name="description"
            content="Connect with experienced mortgage loan consultants to refinance or secure new residential and commercial loans. Get personalized, live chat advice to lower your monthly payments and find the best loan options. Free consultations, fast service, and expert guidance—start saving today!"
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
