import React, { Component } from "react";
import {
  Tabs,
  Tab,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  Button,
  Tooltip,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FiInfo, FiArrowDown } from "react-icons/fi";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  CommonTermsList,
  CreditScoresList,
  states,
  StatesList,
} from "../common/CommonLists";

class CalculatorMortgage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calculatorType: "monthly",
      includeTaxes: false,
      loanAmount: 240000,
      loanTerm: "30-year fixed",
      interestRate: 7.457,
      householdIncome: 58000,
      monthlyDebts: 500,
      downPayment: 60000,
      purchaseBudget: 0,
      monthlyPayment: 0,
      creditScore: "700-720",
      state: "",
      amortizationSchedule: [],
      totalPrincipal: 0,
      totalInterest: 0,
    };
  }
  componentDidMount() {
    // Auto-calculate default values on initial load
    this.calculateMonthlyPayment();
    this.calculatePurchaseBudget();
  }

  calculateMonthlyPayment = () => {
    const { loanAmount, interestRate, loanTerm, includeTaxes } = this.state;
    const rate = interestRate / 100 / 12;
    const payments = loanTerm === "30-year fixed" ? 360 : 180;
    const payment = (loanAmount * rate) / (1 - Math.pow(1 + rate, -payments));
    const monthlyPayment =
      (loanAmount * rate) / (1 - Math.pow(1 + rate, -payments));
    this.setState({ monthlyPayment }, this.calculateAmortizationSchedule);
  };

  calculatePurchaseBudget = () => {
    const { householdIncome, monthlyDebts } = this.state;
    const maxPayment = (householdIncome / 12) * 0.28 - monthlyDebts;
    this.setState({ purchaseBudget: maxPayment * 100 }); // Simplified formula
  };
  calculateAmortizationSchedule = () => {
    const { loanAmount, interestRate, loanTerm } = this.state;
    const rate = interestRate / 100 / 12;
    const payments = loanTerm === "30-year fixed" ? 360 : 180;
    let balance = loanAmount;
    let totalPrincipal = 0;
    let totalInterest = 0;

    const schedule = Array.from({ length: payments }, (_, i) => {
      const interestPayment = balance * rate;
      const principalPayment = this.state.monthlyPayment - interestPayment;
      balance -= principalPayment;

      totalPrincipal += principalPayment;
      totalInterest += interestPayment;

      return {
        month: i + 1,
        principalPayment: principalPayment.toFixed(2),
        interestPayment: interestPayment.toFixed(2),
        balance: balance > 0 ? balance.toFixed(2) : 0,
      };
    });

    this.setState({
      amortizationSchedule: schedule,
      totalPrincipal: totalPrincipal.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ calculatorType: newValue });
  };
  handleInputChange = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  handleSwitchChange = (field) => (event) => {
    this.setState({ [field]: event.target.checked });
  };
  formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  };
  render() {
    const {
      calculatorType,
      loanAmount,
      loanTerm,
      interestRate,
      householdIncome,
      monthlyDebts,
      downPayment,
      purchaseBudget,
      monthlyPayment,
      creditScore,
      state,
      amortizationSchedule,
      totalPrincipal,
      totalInterest,
    } = this.state;

    const states = StatesList; //["California", "Texas", "Florida", "New York", "Others"];
    const creditScores = CreditScoresList;

    const terms = CommonTermsList;
    return (
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--10">
              <h3 className="title">Mortgage Calculator</h3>
              <p className="description">
                Quickly calculate your monthly mortgage payments or determine
                your ideal home purchase budget with our easy-to-use mortgage
                calculator. Tailored for accuracy, it includes taxes, fees, and
                flexible loan term options to fit your financial needs.
              </p>
            </div>
            <Box sx={{ padding: 2, maxWidth: 600, margin: "0 auto" }}>
              <Tabs
                value={calculatorType}
                onChange={this.handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab value="monthly" label="Monthly Payment" />
                <Tab value="budget" label="Purchase Budget" />
              </Tabs>

              {calculatorType === "monthly" ? (
                <>
                  <Box>
                    {/* <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={includeTaxes}
                            onChange={(e) =>
                              this.setState({ includeTaxes: e.target.checked })
                            }
                          />
                        }
                        label="Include Taxes & Fees"
                      />
                      <Tooltip
                        title="Your monthly payment is just more than your mortgage. It can include property taxes, homeowners insurance etc."
                        className="pb-2"
                      >
                        <FiInfo size={30} />
                      </Tooltip>
                    </Box> */}
                    <TextField
                      label="Loan Amount"
                      value={loanAmount}
                      onChange={this.handleInputChange("loanAmount")}
                      InputProps={{
                        startAdornment: <Typography>$</Typography>,
                      }}
                      fullWidth
                      margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Loan Term</InputLabel>
                      <Select
                        value={loanTerm}
                        onChange={this.handleInputChange("loanTerm")}
                      >
                        <MenuItem value="30-year fixed">30-year fixed</MenuItem>
                        <MenuItem value="15-year fixed">15-year fixed</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Interest Rate (%)"
                      value={interestRate}
                      onChange={this.handleInputChange("interestRate")}
                      fullWidth
                      margin="normal"
                    />

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.calculateMonthlyPayment}
                        fullWidth
                        sx={{ mr: 1 }}
                      >
                        Calculate Monthly Payment
                      </Button>
                      <Tooltip
                        title="This calculator provides an estimate based on your input and does not constitute financial advice."
                        placement="right"
                      >
                        <InfoOutlinedIcon color="action" />
                      </Tooltip>
                    </Box>
                    <Typography variant="h6" mt={2}>
                      Monthly Payment: {this.formatCurrency(monthlyPayment)}
                    </Typography>
                    <Typography variant="subtitle1">
                      Total Principal Paid:{" "}
                      {this.formatCurrency(totalPrincipal)}
                    </Typography>
                    <Typography variant="subtitle1">
                      Total Interest Paid: {this.formatCurrency(totalInterest)}
                    </Typography>
                  </Box>
                  <Box mt={4}>
                    <Typography variant="h6" gutterBottom>
                      Amortization Schedule
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: 500, overflowY: "auto" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Month</TableCell>
                            <TableCell>Principal Payment</TableCell>
                            <TableCell>Interest Payment</TableCell>
                            <TableCell>Remaining Balance</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {amortizationSchedule.map((row) => (
                            <TableRow key={row.month}>
                              <TableCell>{row.month}</TableCell>
                              <TableCell>
                                {this.formatCurrency(row.principalPayment)}
                              </TableCell>
                              <TableCell>
                                {this.formatCurrency(row.interestPayment)}
                              </TableCell>
                              <TableCell>
                                {this.formatCurrency(row.balance)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              ) : (
                <Box>
                  <TextField
                    label="Yearly Household Income"
                    value={householdIncome}
                    onChange={this.handleInputChange("householdIncome")}
                    InputProps={{
                      startAdornment: <Typography>$</Typography>,
                    }}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Monthly Debts"
                    value={monthlyDebts}
                    onChange={this.handleInputChange("monthlyDebts")}
                    InputProps={{
                      startAdornment: <Typography>$</Typography>,
                    }}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Down Payment"
                    value={downPayment}
                    onChange={this.handleInputChange("downPayment")}
                    InputProps={{
                      startAdornment: <Typography>$</Typography>,
                    }}
                    fullWidth
                    margin="normal"
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Loan Term</InputLabel>
                    <Select
                      value={loanTerm}
                      onChange={this.handleInputChange("loanTerm")}
                    >
                      <MenuItem value="30-year fixed">30-year fixed</MenuItem>
                      <MenuItem value="15-year fixed">15-year fixed</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>State</InputLabel>
                    <Select
                      value={state}
                      onChange={this.handleInputChange("state")}
                    >
                      {states.map((s) => (
                        <MenuItem key={s} value={s}>
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Credit Score</InputLabel>
                    <Select
                      value={creditScore}
                      onChange={this.handleInputChange("creditScore")}
                    >
                      {creditScores.map((cs) => (
                        <MenuItem key={cs} value={cs}>
                          {cs}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={this.calculatePurchaseBudget}
                  >
                    Calculate Purchase Budget
                  </Button>
                  <Typography variant="h6" mt={2}>
                    Purchase Budget: ${purchaseBudget.toFixed(2)}
                  </Typography>
                </Box>
              )}
            </Box>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <h4>Key Terms</h4>
            <div>
              {terms.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<FiArrowDown />}>
                    <Typography>{item.term}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.details}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatorMortgage;
