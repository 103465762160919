import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import { auth } from "../component/config/firebase";
import {
  EmailAuthProvider,
  linkWithCredential,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { StatesList } from "../component/common/CommonLists";
import { registerAgent } from "../component/config/FBHelper";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        nmlsNumber: "",
        profilePicture: null,
        profilePicturePreview: "",
        profileText: "",
        subscriptionPlan: "",
      },
      selectedImage: null,
      imageUrl: "",
      isSubmitting: false,
      showVerification: false,
      profilePictureEmpty: true,
      verificationCode: "",
      confirmationResult: null,
      recaptcha: null,
      error: "",
      userCreated: false,
    };
    this.auth = auth;
    this.userInfo = {};
    this.firestore = getFirestore();
    this.storage = getStorage();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.handleProfilePictureChange =
      this.handleProfilePictureChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  states = StatesList;

  componentDidMount() {
    auth.settings.appVerificationDisabledForTesting = false;
    //Initialize reCAPTCHA when the component mounts
    window.recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      this.recaptcha,
      {
        size: "invisible", // 'invisible' or 'normal' for visible widget
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("Recaptcha verified");
          this.handleFormSubmit();
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          console.log("Recaptcha expired");
        },
      }
    );
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }
  componentWillUnmount() {
    console.log("unmounting form");
    // Clean up reCAPTCHA verifier if necessary
    window.recaptchaVerifier = null;
  }
  handleInputChange = (field) => (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: event.target.value,
      },
    });
  };

  handleProfilePictureChange(event) {
    if (event) event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      this.setState({
        formData: {
          ...this.state.formData,
          profilePicture: file,
          profilePicturePreview: URL.createObjectURL(file),
          profilePictureEmpty: false,
        },
        imageUrl: URL.createObjectURL(file),
        selectedImage: file,
      });
    }
  }

  validateForm() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      zipCode,
      nmlsNumber,
      profilePicture,
      profilePictureEmpty,
    } = this.state.formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const zipCodeRegex = /^\d{5}$/;
    const nmlsRegex = /^\d{1,10}$/;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !zipCode ||
      !nmlsNumber ||
      !profilePicture
    ) {
      this.setState({ error: "All fields are required." });
      return false;
    }

    if (!emailRegex.test(email)) {
      this.setState({ error: "Invalid email address." });
      return false;
    }

    if (!phoneRegex.test(phoneNumber)) {
      this.setState({ error: "Phone number must be 10 digits." });
      return false;
    }

    if (!zipCodeRegex.test(zipCode)) {
      this.setState({ error: "Zip code must be 5 digits." });
      return false;
    }

    if (!nmlsRegex.test(nmlsNumber)) {
      this.setState({ error: "NMLS number must be up to 10 digits." });
      return false;
    }
    if (profilePictureEmpty) {
      this.setState({ error: "Profile picture is required" });
      return false;
    }

    this.setState({ error: "" });
    return true;
  }

  handleFormSubmit = async (event) => {
    if (event) event.preventDefault();
    if (this.state.isSubmitting) {
      return; // Prevent duplicate submissions
    }

    if (!this.validateForm()) return;

    this.setState({ isSubmitting: true }); // Disa

    // Reset reCAPTCHA if already initialized
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.render().then((widgetId) => {
        window.grecaptcha.reset(widgetId); // Reset reCAPTCHA to ensure freshness
      });
    }

    const appVerifier = window.recaptchaVerifier;
    const phoneWithPrefix = `+1${this.state.formData.phoneNumber}`;
    console.log(phoneWithPrefix);
    try {
      signInWithPhoneNumber(auth, phoneWithPrefix, appVerifier, {
        size: "invisible",
      }).then((result) => {
        console.log("SMS code sent");
        this.setState({ confirmationResult: result, showVerification: true });
      });
    } catch (err) {
      this.setState({
        error: `Failed to send verification code: ${err.message}`,
      });
    }
  };

  async handleVerification() {
    const {
      confirmationResult,
      formData,
      verificationCode,
      imageUrl,
      selectedImage,
    } = this.state;

    try {
      await confirmationResult
        .confirm(verificationCode)
        .then(async (result) => {
          // User signed in successfully.
          const user = result.user;
          const strPassword = Math.random().toString(36).slice(-12);
          // Save data to Firestore
          const uInfo = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phoneNumber,
            zipCode: formData.zipCode,
            address: formData.address,
            city: formData.city,
            stateName: formData.state,
            nmlsNumber: formData.nmlsNumber,
            avatarUrl: imageUrl,
            profileText: formData.profileText,
            activationCode: "",
            isActive: false,
            UID: user.uid,
            password: strPassword,
            status: "Offline",
            subscriptionPlan: formData.subscriptionPlan,
            created: new Date(),
          };
          //this.setState({userInfo: uInfo});
          //link phone auth with email auth
          const credential = EmailAuthProvider.credential(
            formData.email,
            strPassword
          );
          linkWithCredential(user, credential)
            .then((usercred) => {
              const user = usercred.user;
              console.log("Account linking success", user);
            })
            .catch((error) => {
              console.log("Account linking error", error);
            });
          //register loan officer
          const docRef = await registerAgent(uInfo, selectedImage);
          this.setState({ userCreated: true });
        });
    } catch (err) {
      this.setState({ error: `Failed to verify code: ${err.message}` });
    }
  }

  render() {
    const { formData, showVerification, verificationCode, error, userCreated } =
      this.state;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Join Our Network" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Join Our Network"} />
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div ref={(ref) => (this.recaptcha = ref)}></div>
        <div className="rn-contact-page ptb--120 bg_color--1">
          <Box sx={{ padding: "20px", margin: "0 auto" }}>
            {!showVerification ? (
              <form
                id="form1"
                onSubmit={(e) => {
                  this.handleFormSubmit(e);
                }}
              >
                <div className="container">
                  <div className="section-title text-left mb--50">
                    <h2 className="title">Register</h2>
                    <p className="description">
                      Become a member today and start getting qualified leads.
                    </p>
                  </div>
                </div>
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                  <div className="container">
                    <div className="row">
                      {/* Start PRicing Table Area  */}
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="rn-pricing">
                          <div className="pricing-table-inner">
                            <div className="pricing-header">
                              <h4 className="title">Basic</h4>
                              <h6 className="subtitle">
                                Your Business Launchpad
                              </h6>
                              <h6 className="subtitle">
                                For new agents looking to establish themselves
                                and access essential tools.
                              </h6>
                              <div className="pricing">
                                <span className="price">299</span>
                                <span className="subtitle">USD Per Month</span>
                              </div>
                            </div>
                            <div className="pricing-body">
                              <ul className="list-style--1">
                                <li>
                                  <FiCheck /> Up to 10 qualified leads per month
                                </li>
                                <li>
                                  <FiCheck /> Access to Mobile & Desktop Apps
                                </li>
                                <li>
                                  <FiCheck /> Online Profile Visibility
                                  (Limited)
                                </li>
                                <li>
                                  <FiCheck /> Lead Tracking Dashboard
                                </li>
                                <li>
                                  <FiCheck /> 24/7 Agent Support
                                </li>
                              </ul>
                            </div>
                            <div className="pricing-footer">
                              <h6 className="subtitle">
                                Start building your client base today with
                                quality leads!
                              </h6>
                              <a className="rn-btn" href="#pricing">
                                Purchase Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End PRicing Table Area  */}

                      {/* Start PRicing Table Area  */}
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="rn-pricing active">
                          <div className="pricing-table-inner">
                            <div className="pricing-header">
                              <h4 className="title">Standard</h4>
                              <h6 className="subtitle">
                                Fuel Your Business Growth
                              </h6>
                              <h6 className="subtitle">
                                Get everything from the Basic Plan plus more
                                visibility, higher rankings, and more leads.
                              </h6>
                              <div className="pricing">
                                <span className="price">499</span>
                                <span className="subtitle">USD Per Month</span>
                              </div>
                            </div>
                            <div className="pricing-body">
                              <ul className="list-style--1">
                                <li>
                                  <FiCheck /> All basic plan features
                                </li>
                                <li>
                                  <FiCheck /> Up to 50 qualified leads per month
                                </li>
                                <li>
                                  <FiCheck /> Priority profile visibility
                                </li>
                                <li>
                                  <FiCheck /> Website profile showcase
                                </li>
                                <li>
                                  <FiCheck /> Higher Search Rankings
                                </li>
                              </ul>
                            </div>
                            <div className="pricing-footer">
                              <h6 className="subtitle">
                                Boost your business visibility and scale faster!
                              </h6>
                              <a className="rn-btn" href="#pricing">
                                Purchase Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End PRicing Table Area  */}

                      {/* Start PRicing Table Area  */}
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="rn-pricing">
                          <div className="pricing-table-inner">
                            <div className="pricing-header">
                              <h4 className="title">Premium</h4>
                              <h6 className="subtitle">
                                Maximum Exposure & Exclusive Perks
                              </h6>
                              <h6 className="subtitle">
                                For agents serious about dominating their
                                market, becoming an industry leader, and
                                receiving premium perks.
                              </h6>
                              <div className="pricing">
                                <span className="price">699</span>
                                <span className="subtitle">USD Per Month</span>
                              </div>
                            </div>
                            <div className="pricing-body">
                              <ul className="list-style--1">
                                {/* <li>
                                  <FiCheck /> All Starter & Standard Plan
                                  Features
                                </li> */}
                                <li>
                                  <FiCheck /> Unlimited leads per month
                                </li>
                                <li>
                                  <FiCheck /> Priority Profile Ranking
                                </li>
                                <li>
                                  <FiCheck /> New Lead Notification Preferences
                                </li>
                                <li>
                                  <FiCheck /> Missed Lead Access
                                </li>
                                <li>
                                  <FiCheck /> Social Media Spotlights
                                </li>
                              </ul>
                            </div>

                            <div className="pricing-footer">
                              <h6 className="subtitle">
                                Dominate your market with maximum exposure!
                              </h6>
                              <a className="rn-btn" href="#pricing">
                                Purchase Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End PRicing Table Area  */}
                    </div>
                  </div>
                </div>
                {/* End Pricing Tbale Area  */}
                <Box
                  sx={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}
                >
                  <p className="description">
                    Please provide your information.
                  </p>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        value={formData.firstName}
                        onChange={this.handleInputChange("firstName")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        value={formData.lastName}
                        onChange={this.handleInputChange("lastName")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Email Address"
                        type="email"
                        value={formData.email}
                        onChange={this.handleInputChange("email")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Phone Number"
                        type="tel"
                        value={formData.phoneNumber}
                        onChange={this.handleInputChange("phoneNumber")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Address"
                        value={formData.address}
                        onChange={this.handleInputChange("address")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="City"
                        value={formData.city}
                        onChange={this.handleInputChange("city")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>State</InputLabel>
                        <Select
                          value={formData.state}
                          onChange={this.handleInputChange("state")}
                          required
                        >
                          {this.states.map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Zip Code"
                        value={formData.zipCode}
                        onChange={this.handleInputChange("zipCode")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="NMLS Number"
                        value={formData.nmlsNumber}
                        onChange={this.handleInputChange("nmlsNumber")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Profile Highlights"
                        type="text"
                        multiline
                        rows={5}
                        value={formData.profiletext}
                        onChange={this.handleInputChange("profileText")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} align="center">
                      <label htmlFor="profilePicture">
                        <Avatar
                          src={formData.profilePicturePreview}
                          sx={{ width: 120, height: 120, margin: "10px auto" }}
                        />
                        <input
                          id="profilePicture"
                          type="file"
                          accept="image/*"
                          onChange={this.handleProfilePictureChange}
                          style={{ display: "none" }}
                          required
                        />
                        <Button variant="contained" component="span">
                          Upload Profile Picture
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                  <div id="recaptcha-container"></div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    disabled={this.state.isSubmitting} // Disable button during submission
                  >
                    {this.state.isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                  <div className="py-1 text-center">
                    <Typography align="center" variant="caption">
                      By submitting this form, I accept LoanDeer LLC{" "}
                      <a href="/terms">Terms & conditions</a>
                    </Typography>
                  </div>
                  {error && (
                    <Typography color="error" align="center" className="pt-3">
                      {error}
                    </Typography>
                  )}
                </Box>
              </form>
            ) : !userCreated ? (
              <Box>
                <Typography variant="h6" align="center">
                  Verify Your Phone Number
                </Typography>
                {error && (
                  <Typography color="error" align="center">
                    {error}
                  </Typography>
                )}
                <TextField
                  label="Verification Code"
                  value={verificationCode}
                  onChange={(e) =>
                    this.setState({ verificationCode: e.target.value })
                  }
                  fullWidth
                  required
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleVerification}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Verify
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => this.setState({ showVerification: false })}
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  Go Back
                </Button>
              </Box>
            ) : (
              <Typography variant="h6" align="center">
                Profile created. We will review your profile and send activation
                notiication once approved.
              </Typography>
            )}
          </Box>
        </div>
        {/* End Contact Page Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Register;
