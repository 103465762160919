import React, { Component } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";

class AirbnbVsLongTermCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        propertyValue: 300000,
        loanAmount: 240000,
        interestRate: 6.5,
        loanTerm: 30,
        nightlyRate: 150,
        occupancyRate: 70, // in %
        cleaningFeePerStay: 50,
        averageStaysPerMonth: 10,
        monthlyLongTermRent: 1500,
        propertyManagementFeeShortTerm: 20, // in %
        propertyManagementFeeLongTerm: 10, // in %
        monthlyExpenses: 500, // utilities, HOA, insurance
        maintenanceCostPerYear: 2000,
        propertyTaxes: 3000,
      },
      airbnbProfit: 0,
      longTermProfit: 0,
    };
  }

  handleInputChange = (field) => (event) => {
    const value = parseFloat(event.target.value) || 0;
    this.setState(
      (prevState) => ({
        inputs: {
          ...prevState.inputs,
          [field]: value,
        },
      }),
      this.calculateProfit
    );
  };

  calculateMonthlyMortgage = () => {
    const { loanAmount, interestRate, loanTerm } = this.state.inputs;
    const monthlyRate = interestRate / 100 / 12;
    const totalPayments = loanTerm * 12;
    return (
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -totalPayments))
    );
  };

  calculateProfit = () => {
    const {
      nightlyRate,
      occupancyRate,
      cleaningFeePerStay,
      averageStaysPerMonth,
      monthlyLongTermRent,
      propertyManagementFeeShortTerm,
      propertyManagementFeeLongTerm,
      monthlyExpenses,
      maintenanceCostPerYear,
      propertyTaxes,
    } = this.state.inputs;

    // Mortgage
    const monthlyMortgage = this.calculateMonthlyMortgage();

    // Short-Term Rental (Airbnb)
    const occupiedNights = (occupancyRate / 100) * 30; // Assuming 30 days in a month
    const shortTermGrossIncome =
      occupiedNights * nightlyRate + averageStaysPerMonth * cleaningFeePerStay;
    const shortTermManagementFees =
      (propertyManagementFeeShortTerm / 100) * shortTermGrossIncome;
    const shortTermExpenses =
      monthlyMortgage +
      monthlyExpenses +
      shortTermManagementFees +
      maintenanceCostPerYear / 12 +
      propertyTaxes / 12;
    const airbnbProfit = shortTermGrossIncome - shortTermExpenses;

    // Long-Term Rental
    const longTermGrossIncome = monthlyLongTermRent;
    const longTermManagementFees =
      (propertyManagementFeeLongTerm / 100) * longTermGrossIncome;
    const longTermExpenses =
      monthlyMortgage +
      monthlyExpenses +
      longTermManagementFees +
      maintenanceCostPerYear / 12 +
      propertyTaxes / 12;
    const longTermProfit = longTermGrossIncome - longTermExpenses;

    this.setState({ airbnbProfit, longTermProfit });
  };

  componentDidMount() {
    this.calculateProfit();
  }

  render() {
    const { inputs, airbnbProfit, longTermProfit } = this.state;

    return (
      <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Airbnb vs. Long-Term Rental Profitability Calculator
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Compare the profitability of short-term rentals like Airbnb with
          long-term leasing options.
        </Typography>
        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          {/* Property Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Property Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Property Value ($)"
              type="number"
              value={inputs.propertyValue}
              onChange={this.handleInputChange("propertyValue")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Loan Amount ($)"
              type="number"
              value={inputs.loanAmount}
              onChange={this.handleInputChange("loanAmount")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Interest Rate (%)"
              type="number"
              value={inputs.interestRate}
              onChange={this.handleInputChange("interestRate")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Loan Term (Years)"
              type="number"
              value={inputs.loanTerm}
              onChange={this.handleInputChange("loanTerm")}
              fullWidth
            />
          </Grid>

          {/* Short-Term Rental Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Short-Term Rental (Airbnb)</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Nightly Rate ($)"
              type="number"
              value={inputs.nightlyRate}
              onChange={this.handleInputChange("nightlyRate")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Occupancy Rate (%)"
              type="number"
              value={inputs.occupancyRate}
              onChange={this.handleInputChange("occupancyRate")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Cleaning Fee Per Stay ($)"
              type="number"
              value={inputs.cleaningFeePerStay}
              onChange={this.handleInputChange("cleaningFeePerStay")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Average Stays Per Month"
              type="number"
              value={inputs.averageStaysPerMonth}
              onChange={this.handleInputChange("averageStaysPerMonth")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Property Management Fee (%)"
              type="number"
              value={inputs.propertyManagementFeeShortTerm}
              onChange={this.handleInputChange(
                "propertyManagementFeeShortTerm"
              )}
              fullWidth
            />
          </Grid>

          {/* Long-Term Rental Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Long-Term Rental</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Monthly Rent ($)"
              type="number"
              value={inputs.monthlyLongTermRent}
              onChange={this.handleInputChange("monthlyLongTermRent")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Property Management Fee (%)"
              type="number"
              value={inputs.propertyManagementFeeLongTerm}
              onChange={this.handleInputChange("propertyManagementFeeLongTerm")}
              fullWidth
            />
          </Grid>

          {/* Common Costs */}
          <Grid item xs={12}>
            <Typography variant="h6">Common Costs</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Monthly Expenses (Utilities, HOA, Insurance) ($)"
              type="number"
              value={inputs.monthlyExpenses}
              onChange={this.handleInputChange("monthlyExpenses")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Annual Maintenance Costs ($)"
              type="number"
              value={inputs.maintenanceCostPerYear}
              onChange={this.handleInputChange("maintenanceCostPerYear")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Annual Property Taxes ($)"
              type="number"
              value={inputs.propertyTaxes}
              onChange={this.handleInputChange("propertyTaxes")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Results */}
        <Typography variant="h6" align="center">
          Results
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Monthly Profit (Airbnb): ${airbnbProfit.toFixed(2)}
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Monthly Profit (Long-Term): ${longTermProfit.toFixed(2)}
        </Typography>

        {/* Action Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={this.calculateProfit}
          sx={{ mt: 3 }}
        >
          Recalculate
        </Button>
      </Box>
    );
  }
}

export default AirbnbVsLongTermCalculator;
