// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD3WyD7CkdfW9U8IDBqcyeHkGv95n0_6pw",
  authDomain: "loandeer-cf496.firebaseapp.com",
  projectId: "loandeer-cf496",
  storageBucket: "loandeer-cf496.appspot.com",
  messagingSenderId: "1015701825500",
  appId: "1:1015701825500:web:ba4e8473008ec20a355119",
  measurementId: "G-ZTCLVQ7TMC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fb = app;
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
