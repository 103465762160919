import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import imgOne from "../assets/images/blog/bl-big-01.jpg";
import imgTwo from "../assets/images/blog/blog-single-01.png";
import BLogList from "./blog/BlogList";

class BlogDetails extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
  }

  render() {
    const { parent } = this.props;
    const { state } = this.props.location;
    const blog = state;
    const blogDate = new Date(blog.date.seconds * 1000).toLocaleDateString(
      "en-US",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );
    console.log(blogDate);
    if (!blog) {
      return <p>Blog not found.</p>; // Fallback if no state is passed
    }
    return (
      <React.Fragment>
        <PageHelmet pageTitle={blog.title} />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">{blog.title}</h2>
                  <div className="breadcrumb-inner">
                    <ul className="page-list">
                      <li className="breadcrumb-item">
                        <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={`${process.env.PUBLIC_URL}/blog`}>Blogs</Link>
                      </li>
                      <li className="breadcrumb-item active">Blog Details</li>
                    </ul>
                  </div>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      {blogDate}
                    </li>
                    <li>
                      <FiUser />
                      {blog.contributor}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src={require(`../assets/images/blog/${blog.image}`)}
                          alt={blog.description}
                        />
                        <span>{blog.description}</span>
                      </div>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: blog.content }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt--110 pb--70 bg_color--1">
              <div className="col-lg-12">
                <BLogList limit="6" />
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BlogDetails;
